import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class RandomListsService {
  constructor() {}

  dashboardReports = [
    'Shift Notes',
    'cash register',
    'gc reports',
    'login trails',
    'payment revenue'
  ];

  nigerianBanks = [
    'Access Bank',
    'Citibank',
    'Diamond Bank',
    'Dynamic Standard Bank',
    'Ecobank Nigeria',
    'Fidelity Bank Nigeria',
    'First Bank of Nigeria',
    'First City Monument Bank',
    'Globus Bank',
    'Guaranty Trust Bank',
    'Heritage Bank Plc',
    'Jaiz Bank',
    'Keystone Bank Limited',
    'Kuda Bank Limited',
    'Monie Point',
    'Opay',
    'Parallex Bank',
    'Providus Bank Plc',
    'Polaris Bank',
    'Stanbic IBTC Bank Nigeria Limited',
    'Standard Chartered Bank',
    'Sterling Bank',
    'Suntrust Bank Nigeria Limited',
    'TAJ Bank',
    'Union Bank of Nigeria',
    'United Bank for Africa',
    'Unity Bank Plc',
    'No Bank',
    'Wema Bank',
    'Zenith Bank'
  ];

  icons = [
    'icon-screen-desktop',
    'icon-plane',
    'icon-disc',
    'icon-user',
    'icon-people',
    'icon-map',
    'icon-layers',
    'icon-compass',
    'icon-trophy',
    'icon-screen-desktop',
    'icon-chemistry',
    'icon-shield',
    'icon-badge',
    'icon-handbag',
    'icon-diamond',
    'icon-briefcase',
    'icon-basket',
    'icon-basket-loaded',
    'icon-wrench',
    'icon-support',
    'icon-rocket',
    'icon-home',
    'icon-grid',
    'icon-calendar',
    'icon-bubble',
    'icon-camera',
    'icon-bulb',
    'icon-link',
    'icon-paper-plane',
    'icon-flag',
    'icon-heart',
    'icon-drawer',
    'icon-film',
    'icon-present',
    'icon-calendar',
    'icon-cup'
  ];
  ComboCategories = [
    {
      'comboCategory': 'speciality',
      'comboType': 'food',
    },
    {
      'comboCategory': 'Nigeria cuisine',
      'comboType': 'food'
    },
    {
      'comboCategory': 'BreakFast',
      'comboType': 'food'
    },
    {
      'comboCategory': 'Continental',
      'comboType': 'food'
    },
    {
      'comboCategory': 'Starter',
      'comboType': 'food'
    },
    {
      'comboCategory': 'Extras',
      'comboType': 'food'
    },
    {
      'comboCategory': 'Soft drink',
      'comboType': 'drink'
    },
    {
      'comboCategory': 'Beer',
      'comboType': 'drink'
    },
    {
      'comboCategory': 'Wine',
      'comboType': 'drink'
    },
    {
      'comboCategory': 'Smoothies',
      'comboType': 'drink'
    },
    {
      'comboCategory': 'Vodka',
      'comboType': 'drink'
    },
    {
      'comboCategory': 'Champagne',
      'comboType': 'drink'
    },
    {
      'comboCategory': 'Whiskey - Cognac',
      'comboType': 'drink'
    },
    {
      'comboCategory': 'Shisha',
      'comboType': 'drink'
    },
    {
      'comboCategory': 'Stout',
      'comboType': 'drink'
    },
    {
      'comboCategory': 'Gin-Spirits',
      'comboType': 'drink'
    },
    {
      'comboCategory': 'Mocktails',
      'comboType': 'drink'
    },
    {
      'comboCategory': 'Cream Liquor',
      'comboType': 'drink'
    },
    {
      'comboCategory': 'Energy drink',
      'comboType': 'drink'
    },
    {
      'comboCategory': 'Liqour shots - Wine glass',
      'comboType': 'drink'
    },

  ];
  // @ts-ignore
  // FOODDATA = {
  //
  // 'menuId': 'nY4iITxBmzZSRJ2',
  // 'menuName': 'Test Standard Menu',
  // 'hotelId': '6',
  // 'activeFromDate': '2021-09-15T23:00:00.000Z',
  // 'activeToTime': null,
  // 'activeFromTime': null,
  // 'currentStatus': 'active',
  // 'createdBy': 'ZHG57202',
  // 'createdDateTime': '2021-09-16T14:32:50.000Z',
  // 'products': [
  //   {
  //     'comboId': 'IXmjMZccWJM0SkG',
  //     'comboType': 'drink',
  //     'hotelId': '6',
  //     'menuId': 'nY4iITxBmzZSRJ2',
  //     'comboName': '33\'Larger Beer',
  //     'comboPrice': 900,
  //     'price': 900,
  //     'fields': [
  //       {
  //         'fieldId': 'coLWmny70ZK4enI',
  //         'fieldName': '33\'Larger Beer',
  //         'fieldType': 'radio',
  //         'fulfillmentType': 'bar',
  //         'defaultCheckBox': 'undefined',
  //         'orderNumber': 1,
  //         'itemRequired': 'true',
  //         'printOnInvoice': 'true',
  //         'fieldItems': [
  //           {
  //             'fieldItemId': '5vxP2KSqziA8Isp',
  //             'productId': '4rSpQP1OICCH9Ke',
  //             'productName': '33\'Larger Beer',
  //             'adjustmentPrice': 0,
  //             'quantity': 1,
  //             'active': 'true',
  //             'currentQuantity': 0,
  //             'productLocation': 'currentLocation'
  //           }
  //         ]
  //       }
  //     ]
  //   },
  // ]
  // };
  FOODDATA =
    {
      'menuId': 'nY4iITxBmzZSRJ2',
      'menuName': 'Test Standard Menu',
      'hotelId': '6',
      'activeFromDate': '2021-09-15T23:00:00.000Z',
      'activeToTime': null,
      'activeFromTime': null,
      'currentStatus': 'active',
      'createdBy': 'ZHG57202',
      'createdDateTime': '2021-09-16T14:32:50.000Z',
      'products': [
        {
          'comboId': 'IXmjMZccWJM0SkG',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': '33\'Larger Beer',
          'comboPrice': 900,
          'price': 900,
          'fields': [
            {
              'fieldId': 'coLWmny70ZK4enI',
              'fieldName': '33\'Larger Beer',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '5vxP2KSqziA8Isp',
                  'productId': '4rSpQP1OICCH9Ke',
                  'productName': '33\'Larger Beer',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'NAhcgZWukFgpAaV',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Absolute vodka ptn',
          'comboPrice': 12000,
          'price': 12000,
          'fields': [
            {
              'fieldId': 'TbFxLerfl2EOhsp',
              'fieldName': 'Absolute vodka ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'kmKtYLamtJcPKQq',
                  'productId': 'SzHmtTCUZND0j5i',
                  'productName': 'Absolute vodka ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'ctMiDUYNr9FmeEZ',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Amstel malt Ptn',
          'comboPrice': 500,
          'price': 500,
          'fields': [
            {
              'fieldId': '03un7z5WldjqKn5',
              'fieldName': 'Amstel malt Ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'TeAy1PK69O7Iyxb',
                  'productId': 'mFswISKXgNShYmC',
                  'productName': 'Amstel malt Ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'B1O09hpsILhoswo',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Andre brut ptn',
          'comboPrice': 9500,
          'price': 9500,
          'fields': [
            {
              'fieldId': 'bzapUfRICzNFgZR',
              'fieldName': 'Andre brut ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'N1m7fLSRegDTQ98',
                  'productId': 'iwt1aSrO0fvFOHr',
                  'productName': 'Andre brut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'vLXSdTKstsOKm7V',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Andre Rose ptn',
          'comboPrice': 10000,
          'price': 10000,
          'fields': [
            {
              'fieldId': 'V2g5LlQlLXafKll',
              'fieldName': 'Andre Rose ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'CofENzsoRWqlS8o',
                  'productId': '9TBpDa2otjz3r38',
                  'productName': 'Andre Rose ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'wgxLosLcKtU8AjZ',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Baccardi  rum',
          'comboPrice': 12000,
          'price': 12000,
          'fields': [
            {
              'fieldId': 'DJxwU4irkIkUCUQ',
              'fieldName': 'Baccardi  rum',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '0VwQcpbJmYsCZeJ',
                  'productId': 'g9nonkWpjB0NDdg',
                  'productName': 'Baccardi  rum',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': '4AXfApA51PVBz3y',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Bailey\'s Irish (big)cream ptn',
          'comboPrice': 12000,
          'price': 12000,
          'fields': [
            {
              'fieldId': 'xkRHlFoWlWLh7V5',
              'fieldName': 'Bailey\'s Irish (big)cream ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '1CueisOmGPyaMjF',
                  'productId': 'JQghkNI0WtSonGW',
                  'productName': 'Bailey\'s Irish (big)cream ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': '9jWKXqDNINlyNue',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Bitter Lemon ptn',
          'comboPrice': 500,
          'price': 500,
          'fields': [
            {
              'fieldId': 'c2w2rKh4CJKKmoW',
              'fieldName': 'Bitter Lemon ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'd9VVNNiPa4mKVKR',
                  'productId': 'RUXgHV51Q0rbPcp',
                  'productName': 'Bitter Lemon ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'pZ4gauX8vciOss4',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Black Bullet',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': 'HNKcy7psWfK58gT',
              'fieldName': 'Black Bullet',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '1C4R6ao1nrwwESB',
                  'productId': '6cAxHXBcT5XtXBO',
                  'productName': 'Black Bullet',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'TwNpiLDJkWYFZDA',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Bowl of Chips, Plantain, or Yam',
          'comboPrice': 1500,
          'price': 1500,
          'fields': [
            {
              'fieldId': 'NwvOYYy7YuCoqz6',
              'fieldName': 'Chips, Plantain, or Yam',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'hMwHadUyqwkygW7',
                  'productId': '1wCgMKE1dTwl57o',
                  'productName': 'Chips/Fries',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 12,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'AikOpCmn3h6nWYZ',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'AC1w35A9G52BaAt',
                  'productId': 'i3qj0OlJR7X506E',
                  'productName': 'Yam ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 23,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'IO6mSMZBtrK5E2r',
              'fieldName': 'Optional Stew or Egg Sauce',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'PrrTqDpvbMqsksS',
                  'productId': 'L5NxqcFeJad2YhF',
                  'productName': 'Egg ptn',
                  'adjustmentPrice': 300,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'lHGOHyU1GYuDoV3',
                  'productId': '20e8XfIrpfxBFCI',
                  'productName': 'stew ptn',
                  'adjustmentPrice': 300,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'imfGTIibZJiNkfz',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Breakfast On The Go',
          'comboPrice': 3000,
          'price': 3000,
          'fields': [
            {
              'fieldId': 'cS7mx37j4hewJYJ',
              'fieldName': 'Toasted Bread Served with Baked Beans and  Sausage',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'YYye3mqKjjwqNgD',
                  'productId': 'HbgVymCh7v4m0dq',
                  'productName': 'Baked beans ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'rmFIf9wsNdljNbP',
                  'productId': '09DszpmvbKYTnJE',
                  'productName': 'Sausage ptn',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 94,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'frCTwoGFTz3Pxod',
              'fieldName': 'Plain or Spanish Omelet or Boiled Egg',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'kEN8IbBWd50dP8r',
                  'productId': 'L5NxqcFeJad2YhF',
                  'productName': 'Egg ptn',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'DTmJ4Mjy1GX7MZa',
              'fieldName': 'Oatmeal or custard or cornflakes',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 3,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'ZVBekEcqmZ5mS9O',
                  'productId': 'YPSBpnLZBwLYG66',
                  'productName': 'Cornflakes ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '3mbgobHkV5SBkHW',
                  'productId': '4F8J9VPoIBZYy8y',
                  'productName': 'Custard',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'lBgmYKoxjMMepS0',
                  'productId': 'r81cMk754J8Vjdl',
                  'productName': 'Quaker oat ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 10,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'JMs9jGj9EQbolAM',
              'fieldName': 'Tea or coffee',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 4,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'cGgEKBxBRLb9jHm',
                  'productId': 'Ax2gaD4FgQp5gWz',
                  'productName': 'Lipton ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 1,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'asDB3POGYPjmVBf',
                  'productId': 'auVvK2VSj6nLhh8',
                  'productName': 'Nescafe ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 7,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'PesEuldCuVeIsqE',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Budwiser ptn',
          'comboPrice': 1300,
          'price': 1250,
          'fields': [
            {
              'fieldId': 'cBWKhYPH7rwjsHD',
              'fieldName': 'Budwiser ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'engFNxRXn2NJzxx',
                  'productId': 'qsY9vkroSZIbaSv',
                  'productName': 'Budwiser ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'jTHXWzm35KlAQqS',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Campari big ptn',
          'comboPrice': 12000,
          'price': 12000,
          'fields': [
            {
              'fieldId': 'uP47ZrKYkGxregd',
              'fieldName': 'Campari big ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'SY1hJsQ1lUtEpO8',
                  'productId': 'BJAGC3pkWGtOkhu',
                  'productName': 'Campari big ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'YegJkqlN6w1rUMw',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Campari(small)',
          'comboPrice': 7000,
          'price': 7000,
          'fields': [
            {
              'fieldId': '6k37ryH2oQNe0Eu',
              'fieldName': 'Campari(small)',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'XqZ2z9TeSzwkleu',
                  'productId': 'TZtBtu1eU1IkzPQ',
                  'productName': 'Campari(small)',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': '3UZ7ddodSIKpikU',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Carlo Rossi red ptn',
          'comboPrice': 7000,
          'price': 7000,
          'fields': [
            {
              'fieldId': 'Hmz7ud4OpVQBmrh',
              'fieldName': 'Carlo Rossi red ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'k1oDcPg0o95Ga3X',
                  'productId': '6Pu2ide8PGbG87Y',
                  'productName': 'Carlo Rossi red ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'fwOhXEhPiG5gVIl',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Carlo Rossi white ptn',
          'comboPrice': 7000,
          'price': 7000,
          'fields': [
            {
              'fieldId': 'OKnXRKVuSQVgT2T',
              'fieldName': 'Carlo Rossi white ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'cprij5lvHms5JIS',
                  'productId': 'X2vq9cl78XMqVbw',
                  'productName': 'Carlo Rossi white ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'o6qBkqaVN1YXE0m',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Chairman\'s Fish',
          'comboPrice': 6000,
          'price': 6000,
          'fields': [
            {
              'fieldId': '1DQCrPNOWBBrc1G',
              'fieldName': 'Catfish Option',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'OsPFYFFFOiysiy6',
                  'productId': 'Y6ZQRBiGGe7l2qp',
                  'productName': 'Catfish Whole',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 3,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'nf0oSxPzcZr9mEw',
              'fieldName': 'Yam or Plantain Option',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '6KouexOu8IXwuyh',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '5asgiJmEOlc7Uc2',
                  'productId': 'i3qj0OlJR7X506E',
                  'productName': 'Yam ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 23,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'RdpWqDITEBAnykl',
              'fieldName': 'Ingredients',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 3,
              'itemRequired': 'true',
              'printOnInvoice': 'false',
              'fieldItems': [
                {
                  'fieldItemId': 'NZ0SC9JW6N7AB6Q',
                  'productId': 'iAhgXJ9S3AMXY57',
                  'productName': 'Chairman\'s Fish spices ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 5,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'Y3iylxfPzVo88s9',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Chicken ‘n’ Chips',
          'comboPrice': 3000,
          'price': 3000,
          'fields': [
            {
              'fieldId': 'VoE5D96GVT9vz9D',
              'fieldName': 'Chicken',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'oVJbMgwO6lizGEK',
                  'productId': 'zUHVzv5U0MWcqDc',
                  'productName': 'chicken cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'x9OexVY9enMCuzm',
              'fieldName': 'Chip, Plantain, or Yam',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'Ezco5DpmThPpWsK',
                  'productId': '1wCgMKE1dTwl57o',
                  'productName': 'Chips/Fries',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 12,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'VPHK6yiId7fmGBB',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'jjWF54RDVFzBcej',
                  'productId': 'i3qj0OlJR7X506E',
                  'productName': 'Yam ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 23,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'PCaPSJ7JA5lC624',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Chicken Salad',
          'comboPrice': 3000,
          'price': 3000,
          'fields': [
            {
              'fieldId': 'JYcTbQa46guDg17',
              'fieldName': 'Boneless Chicken',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': []
            }
          ]
        },
        {
          'comboId': 'KPWD4DC93PdNPTt',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Chicken Suya (full)',
          'comboPrice': 6000,
          'price': 6000,
          'fields': [
            {
              'fieldId': 'bd62hMJrTW3UWd7',
              'fieldName': 'Chicken Suya',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'pkKWfsAqde2vbMU',
                  'productId': '6eAZt5gbEPVL2sq',
                  'productName': 'Chicken Suya (whole)',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': '76i0gZmiezrnMsK',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Chivata ptn',
          'comboPrice': 1500,
          'price': 1500,
          'fields': [
            {
              'fieldId': 'XzNY5glOVRIiSII',
              'fieldName': 'Chivata ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'qXdEz9WpH3k8tck',
                  'productId': '3z5SmpQ6ymg9u9L',
                  'productName': 'Chivata ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'zK85Vzm1YIiX6ZZ',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Chivita Cocktail',
          'comboPrice': 1500,
          'price': 1500,
          'fields': [
            {
              'fieldId': 'ng2NV7DCcjZJjOX',
              'fieldName': 'Chivita Cocktail',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': []
            }
          ]
        },
        {
          'comboId': 'dhFZwspKUzcHIlM',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Ciroc Vodka',
          'comboPrice': 25000,
          'price': 25000,
          'fields': [
            {
              'fieldId': 'u8fRMxUGrHAkw41',
              'fieldName': 'Ciroc Vodka',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'hyqX0zHICkzidUA',
                  'productId': 'JzcsDUecjMwE2z2',
                  'productName': 'Ciroc Vodka',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'UMzRKN4OK1Vrc1K',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Climax',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': 'kmr27K2Kma7vYJ9',
              'fieldName': 'Climax',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'HEStY4hP2J1UoT8',
                  'productId': 'ofcowWJVe3iEk5d',
                  'productName': 'Climax',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'frJk3OVFxQVwwSi',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Cobb Salad',
          'comboPrice': 3500,
          'price': 3500,
          'fields': [
            {
              'fieldId': 'bEurmqIdTmt58Ni',
              'fieldName': 'Choice of Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'W4MhsK60OTU7Ni1',
                  'productId': '8p6TpBxhmX2wSW2',
                  'productName': 'Beef cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 46,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'lylZbjx6Oa50sS3',
              'fieldName': 'Added Egg',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'WbfEYwyWdgKD5e8',
                  'productId': 'L5NxqcFeJad2YhF',
                  'productName': 'Egg ptn',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'xEuO0usEMBnfNl7',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Cocktails Black Cabal',
          'comboPrice': 3500,
          'price': 3500,
          'fields': [
            {
              'fieldId': 'TGQwJnr1IWLfXe6',
              'fieldName': 'Add Johnny Walker/Black Label',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '2utxeXGQH1FzfpG',
                  'productId': '5m3JAY0tqU0ocne',
                  'productName': 'Shorts Johnny walker Black',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            },
            {
              'fieldId': 'TnDWjpavwfk94sz',
              'fieldName': 'Add Coke (Small)',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'false',
              'fieldItems': [
                {
                  'fieldItemId': 'LV1CfatsuqIiacy',
                  'productId': 'aAfb0ZlxGkllXDE',
                  'productName': 'Coke (Small) for cocktails',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'zCksdzNSb4h04JV',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Cocktails Pick Lady',
          'comboPrice': 4000,
          'price': 4000,
          'fields': [
            {
              'fieldId': 'QBBYgebiozkXvFE',
              'fieldName': 'Ingredients',
              'fieldType': 'checkbox',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'false',
              'printOnInvoice': 'false',
              'fieldItems': [
                {
                  'fieldItemId': 'QZH9BsQIOnXAAVM',
                  'productId': 'PdHiHm6EgTwEW3u',
                  'productName': 'Grenadline',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                },
                {
                  'fieldItemId': '4vFAiHD7bg3ZPgb',
                  'productId': '0i0pqpxI6IVOb3H',
                  'productName': 'PINEAPPLE',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '5hBgrt2FD6P0mx3',
                  'productId': 'aGQkr6833zm3Ln8',
                  'productName': 'WATERMELON',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'TaZSEsozdbheKrW',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Cocktails White Korruption',
          'comboPrice': 3500,
          'price': 3500,
          'fields': [
            {
              'fieldId': 'i99DWLUjOpsxfoh',
              'fieldName': 'Add Vodka',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'false',
              'fieldItems': [
                {
                  'fieldItemId': 'cezORjwL0R5zQkb',
                  'productId': 'rK7Fa5LYkBrry8f',
                  'productName': 'Shots Absolute vodka',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            },
            {
              'fieldId': 'QVZt0BYiqirzhnG',
              'fieldName': 'add Spirit',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'undefined',
              'fieldItems': [
                {
                  'fieldItemId': 'MgOYIdBAjznqVwu',
                  'productId': 'jJEnXHPj1k0C4ky',
                  'productName': 'Sprite (Small) for cocktails',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            },
            {
              'fieldId': 'IiuN93kOJE2aPtj',
              'fieldName': 'Add Hennessey ',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 3,
              'itemRequired': 'true',
              'printOnInvoice': 'undefined',
              'fieldItems': [
                {
                  'fieldItemId': '6FicKQDETPjKXsS',
                  'productId': 'XH0JrUL7SamjWUP',
                  'productName': 'Shorts Hennessy VS',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'X5vYd03FlHdC0sZ',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Coconut Rice',
          'comboPrice': 3500,
          'price': 3500,
          'fields': [
            {
              'fieldId': '97jx6pUARtZ38Hl',
              'fieldName': 'Coconut Rice',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '4iPQV2IRkhQMZ6j',
                  'productId': 'VHEYtBvQa2uZCV8',
                  'productName': 'Rice Raw ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'r2FiSBVKfoOlmBJ',
              'fieldName': 'Choice of Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '7bamAKoOrt0hiZb',
                  'productId': '8p6TpBxhmX2wSW2',
                  'productName': 'Beef cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 46,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'etzi30ywdvwkXjR',
                  'productId': 'Fb9me2MxLLpM13V',
                  'productName': 'Catfish Cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 45,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '4HsxOYZu3BN4VFk',
                  'productId': 'zUHVzv5U0MWcqDc',
                  'productName': 'chicken cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'WFl8pn7pV564aT3',
                  'productId': 'y4UF7fwrQ979hBh',
                  'productName': 'cow leg ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '4UK2A6iMtqUYBVw',
                  'productId': 'EkUSg3vRzzQP7FW',
                  'productName': 'Croakerfish cut  ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '6InjGT4mU3n2pZ9',
                  'productId': '9PDy9yXbwpVM2Mg',
                  'productName': 'Goatmeat cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 48,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'mdu1gSWKZQbkaDO',
                  'productId': 'aENzwRSGMiEhORa',
                  'productName': 'Snail ptn',
                  'adjustmentPrice': 1500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'xbw6DcjsAt5VG8t',
              'fieldName': 'Ingredients',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 3,
              'itemRequired': 'true',
              'printOnInvoice': 'undefined',
              'fieldItems': [
                {
                  'fieldItemId': 'l1VOKcN0RJCAIyj',
                  'productId': 'fBK4dfmqPXZYuic',
                  'productName': 'Coconut spices ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 17,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'XcCjBRzwPmQXdnS',
              'fieldName': 'Optional Plantain',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 3,
              'itemRequired': 'false',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'TjeIhyqj04kOiPe',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'PH11V74kaIu4cys',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Coke ptn',
          'comboPrice': 500,
          'price': 500,
          'fields': [
            {
              'fieldId': 'QtIbArnmezpu0ad',
              'fieldName': 'Coke ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '9dck0VRgm7E2NrF',
                  'productId': '2ySLopD0Ky9V5zQ',
                  'productName': 'Coke ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'v04zVI5KELPa0O2',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Cuvee speciale Rose/red',
          'comboPrice': 7000,
          'price': 7000,
          'fields': [
            {
              'fieldId': 'k9AmYsZY7gJnhcO',
              'fieldName': 'Cuvee speciale Rose/red',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'Vcl2Aar1ZjgyEF0',
                  'productId': 'QSi7Q3HKBRoOfbE',
                  'productName': 'Cuvee speciale Rose/red',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'hxjgKqrn2aVlCox',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Cuvee Speciale White',
          'comboPrice': 7000,
          'price': 7000,
          'fields': [
            {
              'fieldId': 'SY2AY9i5KSntOTx',
              'fieldName': 'Cuvee Speciale White',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'kRU8ERbn124zcAG',
                  'productId': 'IhXrtVPrzTMLUbc',
                  'productName': 'Cuvee Speciale White',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'ltLrWtIpWNcuiyB',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Egg Sandwich',
          'comboPrice': 2500,
          'price': 2500,
          'fields': [
            {
              'fieldId': 'L9O4ho4OpKU0yBZ',
              'fieldName': 'All options selected',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'Hq92RHdPOZMINH4',
                  'productId': 'vGJaLHfF0aNoeug',
                  'productName': 'Bread',
                  'adjustmentPrice': 0,
                  'quantity': 3,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'Re5yeRdTirYP6hJ',
                  'productId': 'L5NxqcFeJad2YhF',
                  'productName': 'Egg ptn',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'Onv4LuJpxCqUbf9',
                  'productId': '1wCgMKE1dTwl57o',
                  'productName': 'Chips/Fries',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 12,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'wSN1k6vjkTcvL96',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Escudo Rojo ptn',
          'comboPrice': 12500,
          'price': 12500,
          'fields': [
            {
              'fieldId': '371osOhp1UpueUI',
              'fieldName': 'Escudo Rojo ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'bBhhG6SWoUYYP3r',
                  'productId': 'oikdPDxSoOfQjbG',
                  'productName': 'Escudo Rojo ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'L50rk3qR5XXrDOT',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Extra Chips Plantain Yam',
          'comboPrice': 0,
          'price': 500,
          'fields': [
            {
              'fieldId': 'Vg2AKsK7BLrG4Dk',
              'fieldName': 'Extra Chips Plantain Yam',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'KwdBXcvTBQERxT5',
                  'productId': '1wCgMKE1dTwl57o',
                  'productName': 'Chips/Fries',
                  'adjustmentPrice': 500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 12,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'Dp8suml1BfevITF',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'AofQHxMP606N5Oa',
                  'productId': 'i3qj0OlJR7X506E',
                  'productName': 'Yam ptn',
                  'adjustmentPrice': 500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 23,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'a8s8Cb7IIf1xCuu',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Fanta ptn',
          'comboPrice': 500,
          'price': 500,
          'fields': [
            {
              'fieldId': 'GKTazKW72cRVHRQ',
              'fieldName': 'Fanta ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'ugdEXD8COe9LAPO',
                  'productId': 'IynCwLCjUnsNIUp',
                  'productName': 'Fanta ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'cvtStO7VgN6KAnb',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Fayrouz ptn',
          'comboPrice': 500,
          'price': 500,
          'fields': [
            {
              'fieldId': 'mGhIvgFOlp0z918',
              'fieldName': 'Fayrouz ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '7uONKUlEdpy7kt6',
                  'productId': 'VBJpCA2I2M3jPy3',
                  'productName': 'Fayrouz ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'Z0crd3qtDmlj4Mf',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Five Alive ptn',
          'comboPrice': 1500,
          'price': 1500,
          'fields': [
            {
              'fieldId': 'TwRabfOIOpXZjrO',
              'fieldName': 'Five Alive ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '4mFbUCcbg5kw4F7',
                  'productId': 'yDLqLeSLDfsfNVa',
                  'productName': 'Five Alive ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': '9TPB76mWxEp7qn6',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Fried Rice',
          'comboPrice': 3500,
          'price': 3500,
          'fields': [
            {
              'fieldId': 'VFugRHp6VrGjsDt',
              'fieldName': 'Fried Rice',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'rwuT9sCUbwdTNk0',
                  'productId': 'VHEYtBvQa2uZCV8',
                  'productName': 'Rice Raw ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'p12tiYsSIp5xYXs',
              'fieldName': 'Choice of Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'OemtU69FPPzCxub',
                  'productId': '8p6TpBxhmX2wSW2',
                  'productName': 'Beef cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 46,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'BcecQatDYyZnG3W',
                  'productId': 'Fb9me2MxLLpM13V',
                  'productName': 'Catfish Cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 45,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'DPk3Z5R0ebPNMay',
                  'productId': 'zUHVzv5U0MWcqDc',
                  'productName': 'chicken cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'fE2IAEQHQfdlxhO',
                  'productId': 'y4UF7fwrQ979hBh',
                  'productName': 'cow leg ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'VzadVoGV2yo0Plg',
                  'productId': 'CD3eyf9Es88d106',
                  'productName': 'Croakerfish whole ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'YNbicIdAAUm1mCZ',
                  'productId': '9PDy9yXbwpVM2Mg',
                  'productName': 'Goatmeat cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 48,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '37pNq6Jz3IsAH6d',
                  'productId': 'aENzwRSGMiEhORa',
                  'productName': 'Snail ptn',
                  'adjustmentPrice': 1500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'rJH509JnrWMzMEL',
              'fieldName': 'Optional Plantain',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 3,
              'itemRequired': 'false',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'DvtbVFcoybAuS9M',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'RxEgsYc7UDpdHZK',
              'fieldName': 'Ingredients ',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 4,
              'itemRequired': 'true',
              'printOnInvoice': 'undefined',
              'fieldItems': [
                {
                  'fieldItemId': 'nYjfJOUS3PSmGEk',
                  'productId': 'sZ6t3ovb6om0067',
                  'productName': 'Fried Rice Spices ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'VYYE0dNZetR4AFL',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'GLENFIDDICH 12YEARS',
          'comboPrice': 28000,
          'price': 28000,
          'fields': [
            {
              'fieldId': 'YT5zTt3iYeUw5Gw',
              'fieldName': 'GLENFIDDICH 12YEARS',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'pjLYC1Ws7pJ5Ab0',
                  'productId': 'EZwTzFyZDvMaIhp',
                  'productName': 'GLENFIDDICH 12YEARS',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': '9sHNPPRnzh4ZCSS',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Gordon\'s gin ptn',
          'comboPrice': 1200,
          'price': 1200,
          'fields': [
            {
              'fieldId': 'MWAKvSRWBxCGY6t',
              'fieldName': 'Gordon\'s gin ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'cadTtm44N2PkJ8Z',
                  'productId': 'dEJw7KkOWkq6pfD',
                  'productName': 'Gordon\'s gin ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': '5mpbA69qdKtgZzT',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Grilled/Barbeque Catfish or Croaker Fish',
          'comboPrice': 6000,
          'price': 6000,
          'fields': [
            {
              'fieldId': 'SKDBSpmuDIZfsYF',
              'fieldName': 'Choice of Fish',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'JScWg5kcFIGSZ9m',
                  'productId': 'Y6ZQRBiGGe7l2qp',
                  'productName': 'Catfish Whole',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 3,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'dWvn0pp5CLJlxoD',
                  'productId': 'CD3eyf9Es88d106',
                  'productName': 'Croakerfish whole ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'EvUJ33b8ZydN0HX',
              'fieldName': 'Chips, Plantain, or Yam',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'false',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'zgti6DYbmu4Vuir',
                  'productId': '1wCgMKE1dTwl57o',
                  'productName': 'Chips/Fries',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 12,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'pU5fzXU5AUgyUU2',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'O0kBwpyJQxA6VVz',
                  'productId': 'i3qj0OlJR7X506E',
                  'productName': 'Yam ptn',
                  'adjustmentPrice': 500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 23,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'RLi3rMei28ld4sQ',
              'fieldName': 'Ingredients',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 3,
              'itemRequired': 'true',
              'printOnInvoice': 'false',
              'fieldItems': [
                {
                  'fieldItemId': 'zqD2XEFCe4jDtYz',
                  'productId': 'peOPjutChffCjIy',
                  'productName': 'BERBECUE SAUCE ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '8T6YiZBN6qu26Fm',
                  'productId': 'ZIujbIjI0RCxmG9',
                  'productName': 'Lasor fish seasoning ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'EUj4XROYasli9xb',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Guinness Malt ptn',
          'comboPrice': 500,
          'price': 500,
          'fields': [
            {
              'fieldId': 'zfgDut0a6SWTQSB',
              'fieldName': 'Guinness Malt ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'PJBJUCZVLY3phYS',
                  'productId': '0aTSxLLIyz41iQc',
                  'productName': 'Guinness Malt ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'yv1pQ21Mn4V7h9R',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Gulder ptn',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': 'BCIzE4AUrhSagaB',
              'fieldName': 'Gulder ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'PXwphpdjT1D8Qzt',
                  'productId': '8KxnbyBcR5L9RKs',
                  'productName': 'Gulder ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'hC0sNeSmSMmsvik',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Harp ptn',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': 'tdxhcu11UQLLEb1',
              'fieldName': 'Harp ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'Dp6SXoli6deiDkD',
                  'productId': '0VJtvoYA7jPVOT9',
                  'productName': 'Harp ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': '66t7k1atn61Ep8I',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Heineken',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': 'VEWT0lW4ujnQKsr',
              'fieldName': 'Heineken',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'Vx4C4ELxLMb9cYR',
                  'productId': 'yoVtkUo5CsDpSVt',
                  'productName': 'Heineken',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'avFjdLeGoi6d9JE',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Hennessy vs (small)ptn',
          'comboPrice': 15000,
          'price': 15000,
          'fields': [
            {
              'fieldId': 'k6jMkMfPHN88dni',
              'fieldName': 'Hennessy vs (small)ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '8aO2ufOG0qqc0Pj',
                  'productId': 'uPNHDBsAJTgigVI',
                  'productName': 'Hennessy vs (small)ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'aiD3XF9CM6KrNbJ',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Hennessy vs ptn',
          'comboPrice': 30000,
          'price': 30000,
          'fields': [
            {
              'fieldId': 'ceCbNR523hP8WEC',
              'fieldName': 'Hennessy vs ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'c5umRfjhuMxDiVf',
                  'productId': 'wWcKCW3fFKPgnap',
                  'productName': 'Hennessy vs ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': '3QpfQzJPfxu7PH3',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Hennessy vsop ptn',
          'comboPrice': 45000,
          'price': 45000,
          'fields': [
            {
              'fieldId': 'Ajz1TXqBFdH6Yb8',
              'fieldName': 'Hennessy vsop ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'bHGJ6YqgyuUUHfW',
                  'productId': 'fNFuSxIYUNSMmMq',
                  'productName': 'Hennessy vsop ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'Q009UjNZbnb4QwF',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Hero ptn',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': 'zlTepgGxbQubtnJ',
              'fieldName': 'Hero ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'buJy0hGdS0kawtf',
                  'productId': 'a8Ij8MIirqPJ2r7',
                  'productName': 'Hero ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'okermDWXvLXXPrq',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Hollandia yoghurt ptn',
          'comboPrice': 1500,
          'price': 1500,
          'fields': [
            {
              'fieldId': 'TlecKWpgmTlvHGt',
              'fieldName': 'Hollandia yoghurt ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'lbxaDZMQxZ0hE1q',
                  'productId': 'KrDp2PHkT74FRF0',
                  'productName': 'Hollandia yoghurt ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'KjDG4P3LAypWQJT',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Ise ewu (Goat Head) ',
          'comboPrice': 3500,
          'price': 3500,
          'fields': [
            {
              'fieldId': 'm0gEXHNpU0zLuzI',
              'fieldName': 'Choice of Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'eaHFuCgyLmROTog',
                  'productId': 'feLfTPKzxphoUla',
                  'productName': 'Goathead Ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': '9W62Ev17aRCirfo',
              'fieldName': 'Ingredients',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'false',
              'printOnInvoice': 'false',
              'fieldItems': [
                {
                  'fieldItemId': 'ZRxi30hvNb3ZXlU',
                  'productId': 'VRsxtareEmdoLEv',
                  'productName': 'Isiewu sauce ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'jezgFbgrelbo6dO',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Jack Daniel ptn',
          'comboPrice': 20000,
          'price': 20000,
          'fields': [
            {
              'fieldId': 'Na84t6fhohAn3B8',
              'fieldName': 'Jack Daniel ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'KLlSrHtDH1GP8Yl',
                  'productId': 'pa76KJloOrIeHrb',
                  'productName': 'Jack Daniel ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'lwR107C92xHH6QJ',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Jameson',
          'comboPrice': 11500,
          'price': 11500,
          'fields': [
            {
              'fieldId': '70EpHmGTx9H4j17',
              'fieldName': 'Jameson',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'SZPsvlTeCkVUosC',
                  'productId': 'vjYAGYlrZnfJNzs',
                  'productName': 'Jameson',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': '9EqgUTlKrxrdhwZ',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Johnny walker Black',
          'comboPrice': 19000,
          'price': 19000,
          'fields': [
            {
              'fieldId': 'K7YGtd142Qm8ynC',
              'fieldName': 'Johnny walker Black',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'qootbVTTo9ko0Co',
                  'productId': 'UtQJWmPv0DnlMKL',
                  'productName': 'Johnny walker Black',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': '3MEbskGQAyrqEDw',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Johnny walker Red',
          'comboPrice': 11000,
          'price': 11000,
          'fields': [
            {
              'fieldId': '0l02nlBGieyWL0p',
              'fieldName': 'Johnny walker Red',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'klrNad3GX8zQ4BO',
                  'productId': 'NqYxsb0UDCuloP7',
                  'productName': 'Johnny walker Red',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'qbBJBdlDsdwRlsn',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Jollof Rice',
          'comboPrice': 3500,
          'price': 3500,
          'fields': [
            {
              'fieldId': 'yX8pWmFrdGgJZq7',
              'fieldName': 'Jollof Rice',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'VxSTzGik6WrcpRA',
                  'productId': 'VHEYtBvQa2uZCV8',
                  'productName': 'Rice Raw ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'zHMwYmQ15h3QyxP',
              'fieldName': 'Choice of Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'pQx4vkawB8lxW8D',
                  'productId': '8p6TpBxhmX2wSW2',
                  'productName': 'Beef cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 46,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'i2ZrU71gphRm1On',
                  'productId': 'Fb9me2MxLLpM13V',
                  'productName': 'Catfish Cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 45,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'WMVac640cgrHLq8',
                  'productId': 'zUHVzv5U0MWcqDc',
                  'productName': 'chicken cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'tJrp1eOUzIJYcFt',
                  'productId': 'y4UF7fwrQ979hBh',
                  'productName': 'cow leg ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'oCS5WgpJZDpAkHy',
                  'productId': 'EkUSg3vRzzQP7FW',
                  'productName': 'Croakerfish cut  ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'remMj0cYRF0iQ2n',
                  'productId': '9PDy9yXbwpVM2Mg',
                  'productName': 'Goatmeat cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 48,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'J8i2Klb5qLnIdrz',
                  'productId': 'aENzwRSGMiEhORa',
                  'productName': 'Snail ptn',
                  'adjustmentPrice': 1500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'eHh8l1HNBZC3xeZ',
              'fieldName': 'Ingredients',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 3,
              'itemRequired': 'false',
              'printOnInvoice': 'false',
              'fieldItems': [
                {
                  'fieldItemId': '8xWL3hmKCgHvzpw',
                  'productId': 'yGnzGzxmEE8o9AN',
                  'productName': 'Jollof Rice spices ptn',
                  'adjustmentPrice': 0,
                  'quantity': 0,
                  'active': 'true',
                  'currentQuantity': 27,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'Byl3hBswlzUmWql',
              'fieldName': 'Optional Plantain',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 4,
              'itemRequired': 'false',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'pBG8ss7sAspeLmy',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'N28FexoUhlSMcxI',
              'fieldName': 'Ingredients',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 5,
              'itemRequired': 'true',
              'printOnInvoice': 'undefined',
              'fieldItems': [
                {
                  'fieldItemId': '068usKuPILyeryX',
                  'productId': 'fs1EnhW120a16jG',
                  'productName': 'Jollof Rice Sauce',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 21,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '5lcZAnrI76KPWvQ',
                  'productId': 'yGnzGzxmEE8o9AN',
                  'productName': 'Jollof Rice spices ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 27,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'Yy0bN7plZN3MB8g',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Just Cabernet Sauvegnon ptn',
          'comboPrice': 7500,
          'price': 7500,
          'fields': [
            {
              'fieldId': 'VeayB5j8TQEl77i',
              'fieldName': 'Just Cabernet Sauvegnon ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'GHClQOsSKUthpBJ',
                  'productId': 'ySXSdcmGQNgbTEd',
                  'productName': 'Just Cabernet Sauvegnon ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'veUnqEol0xo7WCS',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Just Merlot ptn',
          'comboPrice': 7500,
          'price': 7500,
          'fields': [
            {
              'fieldId': 'HVBnxY2QUv2NihP',
              'fieldName': 'Just Merlot ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'iyHwu07B13K6j8n',
                  'productId': 'rHW1MPiv5qAR2rx',
                  'productName': 'Just Merlot ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'dLJyN4wanHhtJCk',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Le filou Rose red',
          'comboPrice': 7500,
          'price': 7500,
          'fields': [
            {
              'fieldId': 'QKoxYZxvC1y0GD9',
              'fieldName': 'Le filou Rose red',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'xYDY3aavXCJ7GBA',
                  'productId': '9stYuZiVtcRmp7G',
                  'productName': 'Le filou Rose red',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'ne8fVkNo6NJmE4d',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Le filou white ptn',
          'comboPrice': 7500,
          'price': 7500,
          'fields': [
            {
              'fieldId': '6jKgQjXzmJbKLYh',
              'fieldName': 'Le filou white ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '6pS4oDnvcIZjMsc',
                  'productId': 'jt5id39SCAr5NXV',
                  'productName': 'Le filou white ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'bdPpbCwHUvlLkFU',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Legend stout',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': 'QbhHuePc2L1X1HR',
              'fieldName': 'Legend stout',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'UmrePI4vnzo7N5j',
                  'productId': 'gIzus1hbDoA9bkK',
                  'productName': 'Legend stout',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'KB2Y2XAC4x6dGbI',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Life',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': 'Yp4r5aePHbCkyd3',
              'fieldName': 'Life',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'T7H1i0GKT4iHCCG',
                  'productId': 'KJBJSpdyhOSqeRF',
                  'productName': 'Life',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'KLEutDNubNfyGPr',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Maetus Rose',
          'comboPrice': 7500,
          'price': 7500,
          'fields': [
            {
              'fieldId': '5OO3dbkJlWHAUzh',
              'fieldName': 'Maetus Rose',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'fqOTs9TqgnmCRx1',
                  'productId': 'N3FH85SY4Ae69j6',
                  'productName': 'Maetus Rose',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'd9TeNYPZArOUo1B',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Maltina',
          'comboPrice': 500,
          'price': 500,
          'fields': [
            {
              'fieldId': '05YuwkLgQp5uer3',
              'fieldName': 'Maltina',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'WpY71Aqixi9gTQz',
                  'productId': '0Bmqre1vEN9X04y',
                  'productName': 'Maltina',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 10,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'J39QIRiOabY1ukZ',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Martini Brut ptn',
          'comboPrice': 10000,
          'price': 10000,
          'fields': [
            {
              'fieldId': 'lBvueJbOJhtOyiJ',
              'fieldName': 'Martini Brut ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'hX85F5zxqDwMv7d',
                  'productId': 'IbRDnCl9TKHghqZ',
                  'productName': 'Martini Brut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'OxhfXCVdXBA35GT',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Martini Rose ptn',
          'comboPrice': 10000,
          'price': 10000,
          'fields': [
            {
              'fieldId': 'Iquvg1yl4pFlTjp',
              'fieldName': 'Martini Rose ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'qLoNsgAc4XgU8Mu',
                  'productId': '9Q9brvo0E60WWuQ',
                  'productName': 'Martini Rose ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'ME48U8iBj2B7xlP',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Mocktails Chapman',
          'comboPrice': 2500,
          'price': 2500,
          'fields': [
            {
              'fieldId': 'tS5fzN9vWCONYPC',
              'fieldName': 'Ingredients',
              'fieldType': 'checkbox',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'false',
              'fieldItems': [
                {
                  'fieldItemId': 'qYWJlmEq63ZJZwx',
                  'productId': 'DElLcRVRWcMtz4x',
                  'productName': 'Angostura Bitter',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                },
                {
                  'fieldItemId': 'z0JN8vplPb5KK9H',
                  'productId': 'PdHiHm6EgTwEW3u',
                  'productName': 'Grenadline',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                },
                {
                  'fieldItemId': 'Exzb4hz8e1H59Dr',
                  'productId': 'jJEnXHPj1k0C4ky',
                  'productName': 'Sprite (Small) for cocktails',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                },
                {
                  'fieldItemId': 'F6lDSFUJLfw6jco',
                  'productId': 'YfE3mfop6vbvKeH',
                  'productName': 'Cucumber',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'KX37SItclqJW3R9',
                  'productId': 'WGdHP9zeHPByu3j',
                  'productName': 'Lime',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'PGYd1xjDSldjKcg',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Mocktails Residency Delight',
          'comboPrice': 3500,
          'price': 3500,
          'fields': [
            {
              'fieldId': 'XN6BY5tNldf2ncK',
              'fieldName': 'Ingredients',
              'fieldType': 'checkbox',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'false',
              'printOnInvoice': 'false',
              'fieldItems': [
                {
                  'fieldItemId': 'aIrh4aN0eWrLMv6',
                  'productId': 'sFeki3yV9AZhNrd',
                  'productName': 'pawpaw',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'KcFeSLTi3mGAoa4',
                  'productId': '0i0pqpxI6IVOb3H',
                  'productName': 'PINEAPPLE',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'QC79pW2zBMZgreq',
                  'productId': 'aGQkr6833zm3Ln8',
                  'productName': 'WATERMELON',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': '4l5bVHa1qLLMxcy',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Moet Brut ptn',
          'comboPrice': 40000,
          'price': 40000,
          'fields': [
            {
              'fieldId': 'YVWdgVCC6Mq3T4V',
              'fieldName': 'Moet Brut ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'A23O7NdKaMmqUsv',
                  'productId': 'JdQ3ruNTOQMxg9J',
                  'productName': 'Moet Brut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'WCzSNycy88ViUxO',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Moet Nectar imperial Rose ptn',
          'comboPrice': 42000,
          'price': 42000,
          'fields': [
            {
              'fieldId': '6cI1ND4OT8wPzpG',
              'fieldName': 'Moet Nectar imperial Rose ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'b7kaLUZo0UalQgM',
                  'productId': '9r13YXBAQwqOkTk',
                  'productName': 'Moet Nectar imperial Rose ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'QazO9mxBIMRxo3R',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Moi-Moi with Fish and Vegetables',
          'comboPrice': 3000,
          'price': 3000,
          'fields': [
            {
              'fieldId': 'MdEFCZEPnloFbjR',
              'fieldName': 'Moi-Moi with Fish and Vegetables',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 's3pMMwCH7JV51tw',
                  'productId': 'LSXxoAJy4wDbQIG',
                  'productName': 'Moi-moi and Fish',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'qwJJn4j1fCAQHor',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Nestle water',
          'comboPrice': 400,
          'price': 400,
          'fields': [
            {
              'fieldId': 'echf3KZK7s9mSLE',
              'fieldName': 'Nestle water',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'rYJmkvXiVAOIfK0',
                  'productId': 'Qdbtbf4BY0IGv7j',
                  'productName': 'Nestle water',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 14,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'NMxfRnvSwKee1CV',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Nkwobi',
          'comboPrice': 3500,
          'price': 3500,
          'fields': [
            {
              'fieldId': '5XnDVUZzQjSEHxF',
              'fieldName': 'Choice of Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'iscuMx0PLhhYWRL',
                  'productId': 'y4UF7fwrQ979hBh',
                  'productName': 'cow leg ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'WYEcCytAIO1g6XW',
              'fieldName': 'Ingredients ',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'false',
              'fieldItems': [
                {
                  'fieldItemId': 'JZDP8lqipHWuc99',
                  'productId': 'CuPrnYhOb4BqMFA',
                  'productName': 'Nkwobi Sauce ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'U4OsOqmaoPaEaO7',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Ocean Beach red ptn',
          'comboPrice': 7500,
          'price': 7500,
          'fields': [
            {
              'fieldId': 'A5oWC2wdrgapSEL',
              'fieldName': 'Ocean Beach red ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'BAIlSHkWBX06Pgi',
                  'productId': 'vVU6bflV12KogyZ',
                  'productName': 'Ocean Beach red ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'nV5LZj2r3AKabwg',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Ocean beach white ptn',
          'comboPrice': 7500,
          'price': 7500,
          'fields': [
            {
              'fieldId': 'KjAR7hYngyYfq39',
              'fieldName': 'Ocean beach white ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'Xn5Sutx7AjfbcSe',
                  'productId': 'j3MjfAmxzhTQDkU',
                  'productName': 'Ocean beach white ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'F6YXFPAVifhW7JU',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Olmeca Tecquila ptn',
          'comboPrice': 14000,
          'price': 14000,
          'fields': [
            {
              'fieldId': 'S7gmvAlx2UH1B07',
              'fieldName': 'Olmeca Tecquila ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'Lp8WypEx1NQqs4A',
                  'productId': 't7zEbEt3AGG66n3',
                  'productName': 'Olmeca Tecquila ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'B4tqCukt6ip6mXK',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Origin Bitters ptn',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': 'ObI3oXy1QxdFjj6',
              'fieldName': 'Origin Bitters ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'cW8ZW4dXjWwb5h5',
                  'productId': 'Dg0VqaaUy79ktUk',
                  'productName': 'Origin Bitters ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'RHRBvLf1auMEMAk',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Orign big ptn',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': 'MMOXp57jp8BSQ5E',
              'fieldName': 'Orign big ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'LLygvA92e9I1lyv',
                  'productId': 'l9Yc3JNagLyldtq',
                  'productName': 'Orign big ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 5,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'NHE5tAseNeoO8uU',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Pepper Soup',
          'comboPrice': 3000,
          'price': 3000,
          'fields': [
            {
              'fieldId': '7HB059TnrexueKk',
              'fieldName': 'Choice of Protien',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'AGaztecCeHoXUVO',
                  'productId': 'Ehp44uZhrTEtcQY',
                  'productName': 'Peppersoup Beef',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'FqWC2I748lwrdSP',
                  'productId': 'Jp3hzcw3TodBTbK',
                  'productName': 'Peppersoup Catfish',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 9,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '42e55Z4bNivMbTk',
                  'productId': '8pUU5AzinwlXI8b',
                  'productName': 'Peppersoup Chicken',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'Sm3E0tYHlqqaJVc',
                  'productId': 'sSlj8HwnUce2e4p',
                  'productName': 'Peppersoup Cowleg',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'e2JkdCcT4DCP8UN',
                  'productId': 'HauuhZVcvBollVX',
                  'productName': 'Peppersoup Goatmeat',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'mnFSdw8DNK8C3Am',
              'fieldName': 'Optional Rice',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'undefined',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'B6twYImvEilH1ie',
                  'productId': 'VHEYtBvQa2uZCV8',
                  'productName': 'Rice Raw ptn',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': '3ugzz3L0OIBR1pV',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Peppered Meat',
          'comboPrice': 3000,
          'price': 3000,
          'fields': [
            {
              'fieldId': 'xoPUojhhD9JX1ok',
              'fieldName': 'Choice of Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'ZgiUJvA8QJ854PB',
                  'productId': '3FuW6Bd5AZnmRsr',
                  'productName': 'Gizard ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'ASobD6BtQAQuybP',
                  'productId': '0avXAFbbCr5ZQy3',
                  'productName': 'Peppered Beef ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 13,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'C6lZDK2SQzZOpyY',
                  'productId': '6djRY1RB5VKAHvY',
                  'productName': 'peppered Chicken ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '1PfZcNvvivZINXh',
                  'productId': 'HwshHGF0HFUFiDl',
                  'productName': 'Peppered Croakerfish',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'riESJ7onXI0Tgd9',
                  'productId': 'R2gBHs2TUQDtWrV',
                  'productName': 'Peppered Goatmeat',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'g5hjODCxmtpYzrL',
              'fieldName': 'Optional Rice, Plantain, or Yam',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'undefined',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '9ocF8QXQGOjxQDA',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'UTyKjPG0dJVln6C',
                  'productId': 'VHEYtBvQa2uZCV8',
                  'productName': 'Rice Raw ptn',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'llwf0Z90wJnWJZ6',
                  'productId': 'i3qj0OlJR7X506E',
                  'productName': 'Yam ptn',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 23,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'r0ADVRxuGLNnP2G',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Peppered Snail',
          'comboPrice': 4500,
          'price': 4500,
          'fields': [
            {
              'fieldId': '1P307KRmzLbGUQX',
              'fieldName': 'Peppered Snail',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'yLl94dzqClxpGJC',
                  'productId': 'aENzwRSGMiEhORa',
                  'productName': 'Snail ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'EeUSV7LY50K4Iw2',
              'fieldName': 'Optional Chips or Plantain',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'undefined',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'HZCskqB7tvjX9Ta',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'pthCb4BeCkyEKT3',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Power horse',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': 'B1HeRhU6JF6TJ1d',
              'fieldName': 'Power horse',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'ZMCYRatvhGoK9Rd',
                  'productId': 'cHk4WmS8FJYzaNO',
                  'productName': 'Power horse',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'srC0FSRxJ5A1rLL',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Pure haven ptn',
          'comboPrice': 4000,
          'price': 4000,
          'fields': [
            {
              'fieldId': 'q9c1Ouh9noxqwXy',
              'fieldName': 'Pure haven ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '7UU3WXBv4HxywTs',
                  'productId': '81niepXy8MAR435',
                  'productName': 'Pure haven ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'NCGhFNVQ1Re19GX',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Ram Suya',
          'comboPrice': 2000,
          'price': 2000,
          'fields': [
            {
              'fieldId': '2UzdocMLTb4IS3P',
              'fieldName': 'Choice of Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '0VDEbMgLNYe330u',
                  'productId': 'MsgF7AoFNZOWhZj',
                  'productName': 'Ram Suya Ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'ty0dRezv5zTll61',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Remy Martins VSOP',
          'comboPrice': 40000,
          'price': 40000,
          'fields': [
            {
              'fieldId': '1rCPVVcvjbMBHwD',
              'fieldName': 'Remy Martins VSOP',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'dbuWWWoq3GhubN7',
                  'productId': 'MrTzlHkhxbzDhiw',
                  'productName': 'Remy Martins VSOP',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'HJG8gu4CcYYAmXv',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Residency Noodles',
          'comboPrice': 3000,
          'price': 3000,
          'fields': [
            {
              'fieldId': 'T5mzampIvUWRovh',
              'fieldName': 'Served with plantain',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'QjEVA50v5qMvW6c',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'ES0rzAy7uu1bzuQ',
              'fieldName': 'Shredded chicken  or beef',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '24lzWN3azP3wwXM',
                  'productId': 'ilKKd80tIwsbYrv',
                  'productName': 'Shredded beef',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'ptzsLXh8uMxbwAI',
                  'productId': 'PXcYp2CRc6iksIX',
                  'productName': 'Shredded Chicken ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'YHL02hA4xm9qhaN',
              'fieldName': 'Tea or Coffee',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 3,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'WwrCQmgzJuZFhE5',
                  'productId': 'Ax2gaD4FgQp5gWz',
                  'productName': 'Lipton ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 1,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'iouXSvKAW2eERYO',
                  'productId': 'auVvK2VSj6nLhh8',
                  'productName': 'Nescafe ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 7,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'UsPLte2hiBALOtT',
              'fieldName': 'Add plain  or Spanish omelet for N400',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 4,
              'itemRequired': 'false',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'JHtIvVS58rpBu8s',
                  'productId': 'L5NxqcFeJad2YhF',
                  'productName': 'Egg ptn',
                  'adjustmentPrice': 400,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'czf36QWOrCh7aTl',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Residency Style Breakfast (Nigeria) ',
          'comboPrice': 3000,
          'price': 3000,
          'fields': [
            {
              'fieldId': '5nO4HONaPVJFYbU',
              'fieldName': 'Yam or Plantain',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '9aJp00aOByJItAG',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '7rHuvYe46lJyMb7',
                  'productId': 'i3qj0OlJR7X506E',
                  'productName': 'Yam ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 23,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'Ubq9SAoYIhLEyBs',
              'fieldName': 'mixed Vegetable fish sauce  or egg sauce',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '6SqGbry410WfCCo',
                  'productId': 'L5NxqcFeJad2YhF',
                  'productName': 'Egg ptn',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'RXRe14O6lmH7E4e',
                  'productId': 'VhBKo2QkzbyVDC1',
                  'productName': 'Iced fish ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'gzU6PegKS2bY5z0',
              'fieldName': 'Tea or coffee',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 3,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'jyUBcP7smRQWTsj',
                  'productId': 'Ax2gaD4FgQp5gWz',
                  'productName': 'Lipton ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 1,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'Kw773NUbaNeF5DS',
                  'productId': 'auVvK2VSj6nLhh8',
                  'productName': 'Nescafe ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 7,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'LDJjuzKSh7QpKyR',
              'fieldName': 'Select if fried yam deep in egg',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 4,
              'itemRequired': 'undefined',
              'printOnInvoice': 'undefined',
              'fieldItems': [
                {
                  'fieldItemId': 'XGKbRZdb1yuvaqG',
                  'productId': 'L5NxqcFeJad2YhF',
                  'productName': 'Egg ptn',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'OqCNIivPdkJ4Hc4',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Satay sauced Goatmeat or Chicken',
          'comboPrice': 3000,
          'price': 3000,
          'fields': [
            {
              'fieldId': 'a5ML944JFAjIIVm',
              'fieldName': 'Choice of Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'ywD9SQwI9OX7fKI',
                  'productId': 'PVwJSiMFzvL0mEh',
                  'productName': 'Satay sauce Chicken',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'PFd51mAEvSfRysL',
                  'productId': '58ntgLQe1lNKHWY',
                  'productName': 'Satay sauce Goatmeat',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'h89vN7PWY9hwWbS',
              'fieldName': 'Optional Chips or Plantain',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'false',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'Dw7iJ3i0jXRXwxx',
                  'productId': '1wCgMKE1dTwl57o',
                  'productName': 'Chips/Fries',
                  'adjustmentPrice': 500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 12,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'lbvQqdBQYdRrURH',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': '2FngCwp3mhWaTW6',
          'comboType': 'others',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Shisha',
          'comboPrice': 4000,
          'price': 4000,
          'fields': [
            {
              'fieldId': 'kl63j7i7HSn0idB',
              'fieldName': 'Shisha',
              'fieldType': 'checkbox',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'false',
              'printOnInvoice': 'false',
              'fieldItems': []
            }
          ]
        },
        {
          'comboId': 'yM54zgxmqXLO0vw',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Shorts Hennessy VS',
          'comboPrice': 2500,
          'price': 2500,
          'fields': [
            {
              'fieldId': 'q00v8bmk0wq50UM',
              'fieldName': 'Shorts Hennessy VS',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'iHiCTiApRJsCPuI',
                  'productId': 'XH0JrUL7SamjWUP',
                  'productName': 'Shorts Hennessy VS',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': '8dxwTvuLLN0X00x',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Shorts Johnny walker Black',
          'comboPrice': 2500,
          'price': 2500,
          'fields': [
            {
              'fieldId': 'NnAqB5sPiz8iM0J',
              'fieldName': 'Shorts Johnny walker Black',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'xCgRTYQhx4SShdd',
                  'productId': '5m3JAY0tqU0ocne',
                  'productName': 'Shorts Johnny walker Black',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'ak56E5PCTh8xTt1',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Shorts Olmeca Tecquila',
          'comboPrice': 2000,
          'price': 2000,
          'fields': [
            {
              'fieldId': 'MzLBpE2ExhEYcXW',
              'fieldName': 'Shorts Olmeca Tecquila',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'XWUuFW1ReeeDa7D',
                  'productId': 'MVqbDtNtgpUSkUX',
                  'productName': 'Shorts Olmeca Tecquila',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'rFQnDG7wwJeJvJc',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Shots Absolute vodka',
          'comboPrice': 1500,
          'price': 1500,
          'fields': [
            {
              'fieldId': 'rTIiechUnIrOFHl',
              'fieldName': 'Shots Absolute vodka',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'Z6y8FNeyV75tTjL',
                  'productId': 'rK7Fa5LYkBrry8f',
                  'productName': 'Shots Absolute vodka',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': '0QrTHVacSRRf7s8',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Small Chops',
          'comboPrice': 2000,
          'price': 2000,
          'fields': [
            {
              'fieldId': '5lkXQpxUbnFNCF6',
              'fieldName': 'Select 1 Option',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'oPdt79L1xBOtk3h',
                  'productId': 'ffP3fefAnaLAiwe',
                  'productName': 'Chicken pie',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'edXBYnSobq8vPoo',
                  'productId': 'QmFleZxta2VEv5i',
                  'productName': 'Meat pie',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'dJ1N3p6zpAJxsnN',
                  'productId': 'JrsAPBCAcMRho7S',
                  'productName': 'samosa',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'E3WsY9TpEekC6Ex',
                  'productId': 'fpLssYERQcpMBag',
                  'productName': 'Spring roll',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'a61nVSNUv6mx5Lq',
              'fieldName': 'Select 2nd Option',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'JBerMdy9VtXL5Bq',
                  'productId': 'ffP3fefAnaLAiwe',
                  'productName': 'Chicken pie',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '2BfjwHONr6p1cbB',
                  'productId': 'QmFleZxta2VEv5i',
                  'productName': 'Meat pie',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '8NAoHz56B0lXn1x',
                  'productId': 'JrsAPBCAcMRho7S',
                  'productName': 'samosa',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'uw3kx1MOVfjcTfK',
                  'productId': 'fpLssYERQcpMBag',
                  'productName': 'Spring roll',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'xbeIuZrXU7JR4RT',
              'fieldName': 'Select 3rd Option',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 3,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'NSosijK4FDoMwli',
                  'productId': 'ffP3fefAnaLAiwe',
                  'productName': 'Chicken pie',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'cfaMdOnDuXeA05v',
                  'productId': 'QmFleZxta2VEv5i',
                  'productName': 'Meat pie',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'zkz28nVeZ1J0yKv',
                  'productId': 'JrsAPBCAcMRho7S',
                  'productName': 'samosa',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '64jolVHpzF11z6f',
                  'productId': 'fpLssYERQcpMBag',
                  'productName': 'Spring roll',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'l79V0zP3LDlWUEl',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'small stout ptn',
          'comboPrice': 800,
          'price': 800,
          'fields': [
            {
              'fieldId': 'eI2zeneHx6NuiCT',
              'fieldName': 'small stout ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'jiybwFyM12RIhoY',
                  'productId': 'Mq66FOsTTfzIvzW',
                  'productName': 'small stout ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'FVdagmoDG0L724R',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Smirnoff double black ptn',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': 'L1SzvGTHM6Hk8ZX',
              'fieldName': 'Smirnoff double black ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'mz6JYjw1adRKRKu',
                  'productId': 'X9l3d6SmVTX65h0',
                  'productName': 'Smirnoff double black ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'TMouBc6eYugdnug',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Smirnoff Ice',
          'comboPrice': 900,
          'price': 900,
          'fields': [
            {
              'fieldId': 'nJVEuMjYO4B8uqV',
              'fieldName': 'Smirnoff Ice',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '3PYH1egEAjJnaCB',
                  'productId': 'fKxEUHyPiQkj3JP',
                  'productName': 'Smirnoff Ice',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'JlynKiMrl6pMF0E',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Smirnoff Vodka ptn',
          'comboPrice': 12000,
          'price': 12000,
          'fields': [
            {
              'fieldId': 'DE1v7754tHO9O1j',
              'fieldName': 'Smirnoff Vodka ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'dBrkJnnMyxiQXOg',
                  'productId': 'j3XC1WfM9XTb1BZ',
                  'productName': 'Smirnoff Vodka ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'Fps4MeWc7k1Sxmx',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Snapp ptn',
          'comboPrice': 900,
          'price': 900,
          'fields': [
            {
              'fieldId': 'xHWHeULH3E6UKsT',
              'fieldName': 'Snapp ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '4ICzUhpZTirEKFr',
                  'productId': 'qoUwBSwag1V9153',
                  'productName': 'Snapp ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'PKE8TVq2lCKbDrh',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Spaghetti Bolognese',
          'comboPrice': 3600,
          'price': 3600,
          'fields': [
            {
              'fieldId': 'S1IfKwzAhEd6UNe',
              'fieldName': 'Spaghetti',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'Ud0JamKd0ai8uDd',
                  'productId': '1dXg5bNQNPFtYjY',
                  'productName': 'SPAGHETTI',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': '9FyR244TDM7KC8f',
              'fieldName': 'minced Beef',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'CjjJF79246DJiJS',
                  'productId': 'kblFj4jCKPJa9et',
                  'productName': 'minced beef',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'd7aVALeVMqzrMkg',
              'fieldName': 'Optional Protein',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 3,
              'itemRequired': 'false',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'BYvMgs6WCnPzpG1',
                  'productId': '8p6TpBxhmX2wSW2',
                  'productName': 'Beef cut ptn',
                  'adjustmentPrice': 2000,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 46,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'W0J7aBtf0wx1wTP',
                  'productId': 'zUHVzv5U0MWcqDc',
                  'productName': 'chicken cut ptn',
                  'adjustmentPrice': 2000,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '8Rc8c0yZ0eltAhp',
                  'productId': 'EkUSg3vRzzQP7FW',
                  'productName': 'Croakerfish cut  ptn',
                  'adjustmentPrice': 2000,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'QbXUdsNLccnsdqg',
                  'productId': '9PDy9yXbwpVM2Mg',
                  'productName': 'Goatmeat cut ptn',
                  'adjustmentPrice': 2000,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 48,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'ds6ZS39smc94NLl',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Sprite (Small) for cocktails',
          'comboPrice': 300,
          'price': 300,
          'fields': [
            {
              'fieldId': 'D7HBwXZx2fZPwu3',
              'fieldName': 'Sprite (Small) for cocktails',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'L6oUfzq2XxForJO',
                  'productId': 'jJEnXHPj1k0C4ky',
                  'productName': 'Sprite (Small) for cocktails',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'wT1pNtHDOaNtmDa',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Sprite ptn',
          'comboPrice': 500,
          'price': 500,
          'fields': [
            {
              'fieldId': 'oxY3tUKSxUuuWO6',
              'fieldName': 'Sprite ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'fFg6kL94jzfUsp1',
                  'productId': 'o0KNw0q0d8puQLj',
                  'productName': 'Sprite ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 5,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'lKJxM21AWR06pVz',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Star ptn',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': 'HQU3MewCE3jnKVw',
              'fieldName': 'Star ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'iqxsRObhZelfg5e',
                  'productId': 'Lw4FIJh8EfCD7WA',
                  'productName': 'Star ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 15,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'ToTY02znwBYroym',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Star radler ptn',
          'comboPrice': 900,
          'price': 900,
          'fields': [
            {
              'fieldId': '4OzDE4wPX7vwRvG',
              'fieldName': 'Star radler ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'B5ftGSleJBQLhor',
                  'productId': 'EM9cmUcq4KpP4N2',
                  'productName': 'Star radler ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 299,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'uTA914cSJIqvTYz',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Starlite ptn',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': '0REljJvCdElDkUP',
              'fieldName': 'Starlite ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'brluGlCZVqr7QxH',
                  'productId': 'U7PNQnzXtFU3oDB',
                  'productName': 'Starlite ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 300,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'mOjBn7mFTpPmABQ',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Stout Extra smooth ptn',
          'comboPrice': 900,
          'price': 900,
          'fields': [
            {
              'fieldId': '8BpWXT5aThFymOQ',
              'fieldName': 'Stout Extra smooth ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'zB4WCHvd9obHF9e',
                  'productId': '48EiEBepErFII77',
                  'productName': 'Stout Extra smooth ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 195,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'XjiYP3UjbwHbl3o',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Stout Medium  ptn',
          'comboPrice': 900,
          'price': 900,
          'fields': [
            {
              'fieldId': 'SPI2Pgg09TwSZqF',
              'fieldName': 'Stout Medium  ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'RpPzVJbLLHXOEJy',
                  'productId': 'zbADgWTI1eu31Yn',
                  'productName': 'Stout Medium  ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 294,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'RkPv8rK0Yiv7Tkf',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Stute Cranberry ptn',
          'comboPrice': 3000,
          'price': 3000,
          'fields': [
            {
              'fieldId': 'Zct3SqWh2rLd1jN',
              'fieldName': 'Stute Cranberry ptn',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'oFD0u2iX0yjDBk9',
                  'productId': 'NFqtRKljTtri6rC',
                  'productName': 'Stute Cranberry ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 1000,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'nXgEKH9kHf2kz8F',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Swallow',
          'comboPrice': 3500,
          'price': 3500,
          'fields': [
            {
              'fieldId': 'PkVh8PuwqhuTN3n',
              'fieldName': 'Choice of Soup',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'C0bapi0QvHwsrjz',
                  'productId': 'XMfg2WfZqwsLrPo',
                  'productName': 'Afang Soup ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 48,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'bucT9zI5X2f0p6d',
                  'productId': 'auZMtXqwfOEPrHx',
                  'productName': 'Bitterleaf soup',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 48,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'pVZsKQl1GQ5QJzS',
                  'productId': '0QIGuYPIBbpXnJF',
                  'productName': 'Egusi soup ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 1,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'oWfi0S1knzUB8KF',
                  'productId': 'j4lfPLRcjhzX1v7',
                  'productName': 'Nsala ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '2tpuQCWPBnRAQaV',
                  'productId': 'rHrIU6DTiorLxl6',
                  'productName': 'Ogbono soup ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 50,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'JiWZ9olsb2biND9',
                  'productId': '7NIcGIvBDCsMJgK',
                  'productName': 'vegetable ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'c3owa5QNMTAs0vv',
              'fieldName': 'Choice of Swallow',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'ti2xaS0TMMt19MR',
                  'productId': 'P8fptftMmvjGVXX',
                  'productName': 'Garri ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 40,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'JCFCD1uKSkoSMDG',
                  'productId': 'yB32o2WoL2EsucR',
                  'productName': 'Poundo flour',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 45,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'ya9zS5FyoRUSY6f',
                  'productId': 'QNQRFsuoahTAZB0',
                  'productName': 'semo flour ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'TIHHWNc6J7vzALK',
                  'productId': 'NFnKPqjHBmhNXSs',
                  'productName': 'wheat ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 49,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'jv9LeVwJpgrE6cA',
              'fieldName': 'Choice of Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 3,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'fDomPGfL1x3M9Is',
                  'productId': '8p6TpBxhmX2wSW2',
                  'productName': 'Beef cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 46,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'Og6VgTlsXsaJoaB',
                  'productId': 'Fb9me2MxLLpM13V',
                  'productName': 'Catfish Cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 45,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 's29LIN1rLbDT1wq',
                  'productId': 'zUHVzv5U0MWcqDc',
                  'productName': 'chicken cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'idJIs3gODhrdpUm',
                  'productId': 'y4UF7fwrQ979hBh',
                  'productName': 'cow leg ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '5Uls7XAx2GVzBJR',
                  'productId': 'EkUSg3vRzzQP7FW',
                  'productName': 'Croakerfish cut  ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'LPeSsjH38MCLiOM',
                  'productId': 'tIj5swojVcPvPSa',
                  'productName': 'Dry fish ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'yUgCCKENIvJeWIb',
                  'productId': '3FuW6Bd5AZnmRsr',
                  'productName': 'Gizard ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'OPOWSaCIwcuNPA2',
                  'productId': '9PDy9yXbwpVM2Mg',
                  'productName': 'Goatmeat cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 48,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '5rb7WyaTpBOMo2h',
                  'productId': 'aENzwRSGMiEhORa',
                  'productName': 'Snail ptn',
                  'adjustmentPrice': 1500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': '5LxWXDh2jGdxaVj',
              'fieldName': 'Extra Protein',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 4,
              'itemRequired': 'undefined',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'dlOkvMBVzCyBYoc',
                  'productId': '8p6TpBxhmX2wSW2',
                  'productName': 'Beef cut ptn',
                  'adjustmentPrice': 2000,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 46,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'OuZOdXkYCdyOsux',
                  'productId': 'Fb9me2MxLLpM13V',
                  'productName': 'Catfish Cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 45,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'P7hHKOZCfId6mRE',
                  'productId': 'zUHVzv5U0MWcqDc',
                  'productName': 'chicken cut ptn',
                  'adjustmentPrice': 2000,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'viKGqrBTqx209Mb',
                  'productId': 'EkUSg3vRzzQP7FW',
                  'productName': 'Croakerfish cut  ptn',
                  'adjustmentPrice': 2000,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'BS15M5d6wuJclK7',
                  'productId': '9PDy9yXbwpVM2Mg',
                  'productName': 'Goatmeat cut ptn',
                  'adjustmentPrice': 2000,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 48,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'Uc4RyuRifJuiYgO',
              'fieldName': 'Extra Swallow',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 5,
              'itemRequired': 'undefined',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '8CPF4keAiQOqxmb',
                  'productId': 'P8fptftMmvjGVXX',
                  'productName': 'Garri ptn',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 40,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '3kJhGPKNoCAR5Oq',
                  'productId': 'yB32o2WoL2EsucR',
                  'productName': 'Poundo flour',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 45,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'yZoZCBmZQzQbEi5',
                  'productId': 'QNQRFsuoahTAZB0',
                  'productName': 'semo flour ptn',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '4gFG4LOTpXGNmbK',
                  'productId': 'NFnKPqjHBmhNXSs',
                  'productName': 'wheat ptn',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 49,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'IELEeTRRFAnYCHa',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Tea Or Coffee Tray',
          'comboPrice': 2000,
          'price': 2000,
          'fields': [
            {
              'fieldId': 'ubu9WiAg5ewlrhI',
              'fieldName': 'fresh bread  or toasted bread, with sausage',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'U6h0lR1QWndmiLa',
                  'productId': 'vGJaLHfF0aNoeug',
                  'productName': 'Bread',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'ogxbGdb2ph7le57',
                  'productId': '09DszpmvbKYTnJE',
                  'productName': 'Sausage ptn',
                  'adjustmentPrice': 0,
                  'quantity': 2,
                  'active': 'true',
                  'currentQuantity': 94,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'nm5ArQ0dqkfZ7K7',
              'fieldName': 'Tea or coffee',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'e5Ul0Eamjl7EC0s',
                  'productId': 'Ax2gaD4FgQp5gWz',
                  'productName': 'Lipton ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 1,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '5Zhp8zImPgHcYdi',
                  'productId': 'auVvK2VSj6nLhh8',
                  'productName': 'Nescafe ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 7,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'gzWRsPRdef5nfaa',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Test Combo 35',
          'comboPrice': 10000,
          'price': 10000,
          'fields': [
            {
              'fieldId': '30aET0C1tFqFOCU',
              'fieldName': 'Test Drink 1',
              'fieldType': 'checkbox',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'true',
              'orderNumber': 121,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'lClt7zVnyNeD0Sp',
                  'productId': '0aTSxLLIyz41iQc',
                  'productName': 'Guinness Malt ptn',
                  'adjustmentPrice': 3000,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                },
                {
                  'fieldItemId': 'RRoAbNYmTcjw9yq',
                  'productId': 'o0KNw0q0d8puQLj',
                  'productName': 'Sprite ptn',
                  'adjustmentPrice': 2000,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 5,
                  'productLocation': 'currentLocation'
                }
              ]
            },
            {
              'fieldId': 'KYMEbpgpPn0Hb7Q',
              'fieldName': 'Test Drink 2',
              'fieldType': 'checkbox',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'true',
              'orderNumber': 200,
              'itemRequired': 'undefined',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'NgtafdQqSeCHGzV',
                  'productId': '2ySLopD0Ky9V5zQ',
                  'productName': 'Coke ptn',
                  'adjustmentPrice': 1000,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                },
                {
                  'fieldItemId': 'jL25nRnIzDFn9kS',
                  'productId': 'IynCwLCjUnsNIUp',
                  'productName': 'Fanta ptn',
                  'adjustmentPrice': 2000,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'vkGgMgmnwZqRy9i',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Trophy beer',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': '3PtbEHjEejy3Wjt',
              'fieldName': 'Trophy beer',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'CJUVOTRe6IV14Zc',
                  'productId': 'fV6NQkG2EsMVR2r',
                  'productName': 'Trophy beer',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 300,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'rtGO7e0TMYmtYEr',
          'comboType': 'drink',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Trouphy Stout',
          'comboPrice': 1000,
          'price': 1000,
          'fields': [
            {
              'fieldId': 'mPdg1gXrPfhO36y',
              'fieldName': 'Trouphy Stout',
              'fieldType': 'radio',
              'fulfillmentType': 'bar',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'FKNdQvWUbCYxpVg',
                  'productId': 'v4JeQjIi9SxnhLs',
                  'productName': 'Trouphy Stout',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 290,
                  'productLocation': 'currentLocation'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'AKrVvKOLBEf0ZVk',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Vegetable  Plate',
          'comboPrice': 3500,
          'price': 3500,
          'fields': [
            {
              'fieldId': 'TYZrUmZ0Dl7w75G',
              'fieldName': 'Select Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'y3A2t7RKZp5Mv4B',
                  'productId': '8p6TpBxhmX2wSW2',
                  'productName': 'Beef cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 46,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '0arqFog5r6E6U7q',
                  'productId': 'zUHVzv5U0MWcqDc',
                  'productName': 'chicken cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'bqcN9y7BeMhrSgl',
                  'productId': 'EkUSg3vRzzQP7FW',
                  'productName': 'Croakerfish cut  ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'gsHGBuNvC18SgXu',
                  'productId': 'tIj5swojVcPvPSa',
                  'productName': 'Dry fish ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '0ZtE7qSCgMCd6la',
                  'productId': '9PDy9yXbwpVM2Mg',
                  'productName': 'Goatmeat cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 48,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'nh4jdIcZF3kWpXS',
                  'productId': 'aENzwRSGMiEhORa',
                  'productName': 'Snail ptn',
                  'adjustmentPrice': 1500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'HuXOYB8FCl8c3xE',
              'fieldName': 'Add optional Chips, Plantain, Rice, Yam',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'false',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'X406YBUF9J3fCQz',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '0CS92QIl6OMxYZh',
                  'productId': 'VHEYtBvQa2uZCV8',
                  'productName': 'Rice Raw ptn',
                  'adjustmentPrice': 500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'ASI2mmioOqNW7KI',
                  'productId': 'i3qj0OlJR7X506E',
                  'productName': 'Yam ptn',
                  'adjustmentPrice': 500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 23,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'qTbENb4e3j8RpM7',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Vegetable Meat Bowl',
          'comboPrice': 3000,
          'price': 3000,
          'fields': [
            {
              'fieldId': 'SzmbA9jHgJee28x',
              'fieldName': 'Choice of Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': '6c5TYp0pJRVVzck',
                  'productId': '8p6TpBxhmX2wSW2',
                  'productName': 'Beef cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 46,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'Xs4gMWhd29O9zHC',
                  'productId': 'zUHVzv5U0MWcqDc',
                  'productName': 'chicken cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'sXaauPEI0DPw6c0',
                  'productId': 'y4UF7fwrQ979hBh',
                  'productName': 'cow leg ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'zVlnhMXSXwfnl7Z',
                  'productId': 'EkUSg3vRzzQP7FW',
                  'productName': 'Croakerfish cut  ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'izmb1gR4EzWVBB9',
                  'productId': 'tIj5swojVcPvPSa',
                  'productName': 'Dry fish ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'Cho0T6IkOK9ThXE',
                  'productId': '9PDy9yXbwpVM2Mg',
                  'productName': 'Goatmeat cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 48,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'pC7EeKmlBqKaMY3',
              'fieldName': 'Optional Rice, Plantain, or Yam',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'undefined',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'tP7Yn8y7B0pnFIC',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'eelKi0O0wV14iuU',
                  'productId': 'VHEYtBvQa2uZCV8',
                  'productName': 'Rice Raw ptn',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'x6eEbsLRVfSySsk',
                  'productId': 'i3qj0OlJR7X506E',
                  'productName': 'Yam ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 23,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': '0d0UPCzA5YeX0Iq',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Vegetable Platter Full Chicken or Fish',
          'comboPrice': 5500,
          'price': 5500,
          'fields': [
            {
              'fieldId': 'MVrp5KpclqEVx1a',
              'fieldName': 'Choice of Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'EFbYu2rPep9mfwx',
                  'productId': 'Y6ZQRBiGGe7l2qp',
                  'productName': 'Catfish Whole',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 3,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'DBI1yCPxGDthtXw',
                  'productId': '6eAZt5gbEPVL2sq',
                  'productName': 'Chicken Suya (whole)',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'k3QFDZszUePuNmz',
                  'productId': 'CD3eyf9Es88d106',
                  'productName': 'Croakerfish whole ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'dKrf1727UYnBEIA',
              'fieldName': 'Optional Rice, Yam, Plantain, or chips',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'undefined',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'XMko3G6ir1Rfpem',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'NY4jHbiw4zwD6zj',
                  'productId': 'VHEYtBvQa2uZCV8',
                  'productName': 'Rice Raw ptn',
                  'adjustmentPrice': 500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'F9mWGJMcYnbEoVR',
                  'productId': 'i3qj0OlJR7X506E',
                  'productName': 'Yam ptn',
                  'adjustmentPrice': 500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 23,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'hUwQoU78VWp2bPr',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'White Rice with Vegetable or Stew',
          'comboPrice': 3500,
          'price': 3500,
          'fields': [
            {
              'fieldId': 'cMXfF95gKvDjH01',
              'fieldName': 'White rice',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'TRjir4S8WrbaEgM',
                  'productId': 'VHEYtBvQa2uZCV8',
                  'productName': 'Rice Raw ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': '9f0wAj78xCOLmvP',
              'fieldName': 'Option of Vegetable or Stew',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'vmnLWneTqEx0c8f',
                  'productId': 'VhBKo2QkzbyVDC1',
                  'productName': 'Iced fish ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '1uOdVUI5JOHWfhY',
                  'productId': '20e8XfIrpfxBFCI',
                  'productName': 'stew ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'JVEw71n1Oxys8tM',
              'fieldName': 'Choice of Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 3,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'KuV8cQWUx5Sg3jk',
                  'productId': '8p6TpBxhmX2wSW2',
                  'productName': 'Beef cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 46,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'AItxtWhBZ6Di5QT',
                  'productId': 'Fb9me2MxLLpM13V',
                  'productName': 'Catfish Cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 45,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': '2mnoAHsnn8eMxQx',
                  'productId': 'zUHVzv5U0MWcqDc',
                  'productName': 'chicken cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'XfaIV0NIhzykGPh',
                  'productId': 'y4UF7fwrQ979hBh',
                  'productName': 'cow leg ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'DWVUHbiGFVqmF0v',
                  'productId': 'EkUSg3vRzzQP7FW',
                  'productName': 'Croakerfish cut  ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'iXC8TREelIM0n0M',
                  'productId': '9PDy9yXbwpVM2Mg',
                  'productName': 'Goatmeat cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 48,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'JswI4ycJTGS55PJ',
                  'productId': 'aENzwRSGMiEhORa',
                  'productName': 'Snail ptn',
                  'adjustmentPrice': 1500,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': 'J5tjtO9E49NYsdY',
              'fieldName': 'Optional Plantains',
              'fieldType': 'checkbox',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 4,
              'itemRequired': 'undefined',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'j47bEpKVuumEZIW',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 600,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        },
        {
          'comboId': 'XKdxz4ibd5isWBH',
          'comboType': 'food',
          'hotelId': '6',
          'menuId': 'nY4iITxBmzZSRJ2',
          'comboName': 'Yam Or Plantain Pottage ',
          'comboPrice': 3500,
          'price': 3500,
          'fields': [
            {
              'fieldId': '2flKwq2H8NdSExC',
              'fieldName': 'Yam Or Plantain ',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 1,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'mU8hDEUUEW4iP4x',
                  'productId': 'lj7EzJG5r9xdKZP',
                  'productName': 'Plantain Ripe ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'PZUZqWxea6FF0P4',
                  'productId': 'i3qj0OlJR7X506E',
                  'productName': 'Yam ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 23,
                  'productLocation': 'kitchen'
                }
              ]
            },
            {
              'fieldId': '2ecBZ9v5CmfH1GU',
              'fieldName': 'Choice of Protein',
              'fieldType': 'radio',
              'fulfillmentType': 'kitchen',
              'defaultCheckBox': 'undefined',
              'orderNumber': 2,
              'itemRequired': 'true',
              'printOnInvoice': 'true',
              'fieldItems': [
                {
                  'fieldItemId': 'BL0nQJLkLCHaciy',
                  'productId': '8p6TpBxhmX2wSW2',
                  'productName': 'Beef cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 46,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'CrovcIHnpXxQ0Xb',
                  'productId': 'zUHVzv5U0MWcqDc',
                  'productName': 'chicken cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 6,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'XY8pQs3EtE7ZWaM',
                  'productId': 'EkUSg3vRzzQP7FW',
                  'productName': 'Croakerfish cut  ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'l5caR7kFI9bfzHf',
                  'productId': 'tIj5swojVcPvPSa',
                  'productName': 'Dry fish ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 0,
                  'productLocation': 'kitchen'
                },
                {
                  'fieldItemId': 'cpqNgLFhwEq32uC',
                  'productId': '9PDy9yXbwpVM2Mg',
                  'productName': 'Goatmeat cut ptn',
                  'adjustmentPrice': 0,
                  'quantity': 1,
                  'active': 'true',
                  'currentQuantity': 48,
                  'productLocation': 'kitchen'
                }
              ]
            }
          ]
        }
      ]
    };
  weekdays = [
    {
      id: 0,
      name: 'Sunday'
    },
    {
      id: 1,
      name: 'Monday'
    },
    {
      id: 2,
      name: 'Tuesday'
    },
    {
      id: 3,
      name: 'Wednesday'
    },
    {
      id: 4,
      name: 'Thursday'
    },
    {
      id: 5,
      name: 'Friday'
    },
    {
      id: 6,
      name: 'Saturday'
    }
  ];

  monthsList = [
    {
      name: 'January',
      value: 1,
      daysNum: 31
    },
    {
      name: 'February',
      value: 2,
      daysNum: 29
    },
    {
      name: 'March',
      value: 3,
      daysNum: 31
    },
    {
      name: 'April',
      value: 4,
      daysNum: 30
    },
    {
      name: 'May',
      value: 5,
      daysNum: 31
    },
    {
      name: 'June',
      value: 6,
      daysNum: 30
    },
    {
      name: 'July',
      value: 7,
      daysNum: 31
    },
    {
      name: 'August',
      value: 8,
      daysNum: 31
    },
    {
      name: 'September',
      value: 9,
      daysNum: 30
    },
    {
      name: 'October',
      value: 10,
      daysNum: 31
    },
    {
      name: 'November',
      value: 11,
      daysNum: 30
    },
    {
      name: 'December',
      value: 12,
      daysNum: 31
    }
  ];

  yearsList = [
    2020,
    2021,
    2022,
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030
  ];

  countryList = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas (the)',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia (Plurinational State of)',
    'Bonaire, Sint Eustatius and Saba',
    'Bosnia and Herzegovina',
    'Botswana',
    'Bouvet Island',
    'Brazil',
    'British Indian Ocean Territory (the)',
    'Brunei Darussalam',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cayman Islands (the)',
    'Central African Republic (the)',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos (Keeling) Islands (the)',
    'Colombia',
    'Comoros (the)',
    'Congo (the Democratic Republic of the)',
    'Congo (the)',
    'Cook Islands (the)',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Curaçao',
    'Cyprus',
    'Czechia',
    'Côte d\'Ivoire',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic (the)',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Falkland Islands (the) [Malvinas]',
    'Faroe Islands (the)',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'French Southern Territories (the)',
    'Gabon',
    'Gambia (the)',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Heard Island and McDonald Islands',
    'Holy See (the)',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran (Islamic Republic of)',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea (the Democratic People\'s Republic of)',
    'Korea (the Republic of)',
    'Kuwait',
    'Kyrgyzstan',
    'Lao People\'s Democratic Republic (the)',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands (the)',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia (Federated States of)',
    'Moldova (the Republic of)',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands (the)',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger (the)',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'Northern Mariana Islands (the)',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine, State of',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines (the)',
    'Pitcairn',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Republic of North Macedonia',
    'Romania',
    'Russian Federation (the)',
    'Rwanda',
    'Réunion',
    'Saint Barthélemy',
    'Saint Helena, Ascension and Tristan da Cunha',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Martin (French part)',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Sint Maarten (Dutch part)',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia and the South Sandwich Islands',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan (the)',
    'Suriname',
    'Svalbard and Jan Mayen',
    'Sweden',
    'Switzerland',
    'Syrian Arab Republic',
    'Taiwan (Province of China)',
    'Tajikistan',
    'Tanzania, United Republic of',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands (the)',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates (the)',
    'United Kingdom of Great Britain and Northern Ireland (the)',
    'United States Minor Outlying Islands (the)',
    'United States of America (the)',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Venezuela (Bolivarian Republic of)',
    'Viet Nam',
    'Virgin Islands (British)',
    'Virgin Islands (U.S.)',
    'Wallis and Futuna',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe',
    'Åland Islands'
  ];

  emailRegex = new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  );

  parseDateTime(dateTime) {
    const dateTimeArray = [];
    const tempDateTime = dateTime.split(/[-:T. ]/);

    for (const i of tempDateTime) {
      dateTimeArray.push(+i);
    }
    return `${dateTimeArray[0]}-${dateTimeArray[1]}-${dateTimeArray[2]} ${dateTimeArray[3]}:${dateTimeArray[4]}:00`;
  }

  extractDateTime(dateTime) {
    if (dateTime === null || dateTime === undefined) {
      return '-1';
    } else {
      const resDateTime = dateTime.split(/[-T.: ]/);
      let hourIncrease;
      if (+resDateTime[3] + 1 === 24) {
        hourIncrease = 0;
      } else {
        hourIncrease = +resDateTime[3] + 1;
      }
      resDateTime[3] = hourIncrease.toString();

      if (hourIncrease < 12) {
        let hShow;
        if (hourIncrease < 10) {
          hShow = '0' + resDateTime[3];
        } else {
          hShow = resDateTime[3];
        }
        return `${resDateTime[2]}/${resDateTime[1]}/${resDateTime[0]} ${hShow}:${resDateTime[4]} AM`;
      } else {
        let adjustedTime;
        if (hourIncrease === 12) {
          adjustedTime = 12;
        } else {
          adjustedTime = hourIncrease % 12;
        }
        if (adjustedTime < 10) {
          adjustedTime = '0' + adjustedTime;
        }
        return `${resDateTime[2]}/${resDateTime[1]}/${resDateTime[0]} ${adjustedTime}:${resDateTime[4]} PM`;
      }
    }
  }

   extractDate(dateTime) {
    if (dateTime === null || dateTime === undefined) {
      return '-1';
    } else {
      const resDateTime = dateTime.split(/[-T.: ]/);
      return `${resDateTime[2]}/${resDateTime[1]}/${resDateTime[0]}`;
    }
  }


  dateTimeHourIncrease(dateTime) {
    const resDateTime = dateTime.split(/[-T.: ]/);
    const hourIncrease = +resDateTime[3] + 1;
    resDateTime[3] = hourIncrease.toString();
    return `${resDateTime[0]}-${resDateTime[1]}-${resDateTime[2]} ${resDateTime[3]}:${resDateTime[4]}:${resDateTime[5]}`;
  }

  getHoursDifference(dateTime) {
    const time1 = moment()
      .format()
      .split(/[-T.:+ ]/);
    const date1 = new Date(
      `${time1[1]}/${time1[2]}/${time1[0]} ${time1[3]}:${time1[4]}:${time1[5]}`
    );
    const time2 = dateTime.split(/[-T.:+ ]/);
    const date2 = new Date(
      `${time2[1]}/${time2[2]}/${time2[0]} ${time2[3]}:${time2[4]}:${time2[5]}`
    );

    // @ts-ignore
    const milliseconds = Math.abs(date1 - date2);
    return Math.floor(milliseconds / 36e5);
  }

  getDaysNumber(dateTime, dateTime2 = null) {
    let date1;
    if (dateTime2) {
      const time1 = dateTime2.split(/[-T.:+ ]/);
      date1 = new Date(
        `${time1[1]}/${time1[2]}/${time1[0]} ${time1[3]}:${time1[4]}:00`
      );
    } else {
      const time1 = moment()
        .format()
        .split(/[-T.:+ ]/);
      date1 = new Date(
        `${time1[1]}/${time1[2]}/${time1[0]} ${time1[3]}:${time1[4]}:${time1[5]}`
      );
    }
    const time2 = dateTime.split(/[-T.:+ ]/);
    const date2 = new Date(`${time2[1]}/${time2[2]}/${time2[0]} 13:00:00`);
    let numOfDays;

    // @ts-ignore
    const diffTime = Math.abs(date2 - date1);
    const hoursDiff = Math.floor(diffTime / 36e5);
    if (hoursDiff < 8) {
      numOfDays = 1;
    } else {
      numOfDays = Math.floor((hoursDiff - 6) / 24) + 1;
    }
    return numOfDays;
  }

  getDaysDifference(dateTime1, dateTime2) {
    const time1 = dateTime1.split(/[-T.:+ ]/);
    const date1 = new Date(
      `${time1[1]}/${time1[2]}/${time1[0]} ${time1[3]}:${time1[4]}:${time1[5]}`
    );
    const time2 = dateTime2.split(/[-T.:+ ]/);
    const date2 = new Date(
      `${time2[1]}/${time2[2]}/${time2[0]} ${time2[3]}:${time2[4]}:${time2[5]}`
    );
    let numOfDays;

    // @ts-ignore
    const diffTime = date2 - date1;
    const hoursDiff = Math.floor(diffTime / 36e5);
    if (hoursDiff < 0) {
      numOfDays = 0;
    } else if (hoursDiff < 8) {
      numOfDays = 1;
    } else {
      numOfDays = Math.floor((hoursDiff - 8) / 24) + 1;
    }
    return numOfDays;
  }

  calculateDaysLeft(dateToCalculate) {
    const time1 = moment()
      .format()
      .split(/[-T.:+]/);
    const date1 = new Date(`${time1[1]}/${time1[2]}/${time1[0]}`);
    let daysLeft;

    if (dateToCalculate !== null) {
      const time2 = dateToCalculate.split(/[-T.:+]/);
      const date2 = new Date(`${time2[1]}/${time2[2]}/${time2[0]}`);

      if (date2 >= date1) {
        // @ts-ignore
        const diffTime = Math.abs(date2 - date1);
        daysLeft = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      } else {
        daysLeft = -1;
      }
    } else {
      daysLeft = null;
    }
    return daysLeft;
  }

  calculateExtraHoursStayed(hourDiff) {
    let extraHourSpent;
    if (hourDiff >= 3 && hourDiff <= 8) {
      extraHourSpent = 1;
    } else if (hourDiff > 8 && hourDiff <= 16) {
      extraHourSpent = 2;
    } else if (hourDiff > 16 && hourDiff <= 24) {
      extraHourSpent = 3;
    } else if (hourDiff > 24 && hourDiff <= 32) {
      extraHourSpent = 4;
    } else if (hourDiff > 32 && hourDiff <= 40) {
      extraHourSpent = 5;
    } else {
      extraHourSpent = 10;
    }

    return extraHourSpent;
  }

  getTimeSpentAtWork(clockedInDateTime) {
    const time1 = moment()
      .format()
      .split(/[-T.:+ ]/);
    const currentDateTime = new Date(
      `${time1[1]}/${time1[2]}/${time1[0]} ${time1[3]}:${time1[4]}:${time1[5]}`
    );

    let time2;
    if (+clockedInDateTime.split(/[-T.:+ ]/)[3] === 23) {
      const time = clockedInDateTime.split(/[-T.:+ ]/);
      time2 = [time[0], time[1], +time[2] + 1, '00', time[4], time[5]];
    } else {
      time2 = this.dateTimeHourIncrease(clockedInDateTime).split(/[-T.:+ ]/);
    }
    const clockedInDateTimeObj = new Date(
      `${time2[1]}/${time2[2]}/${time2[0]} ${time2[3]}:${time2[4]}:${time2[5]}`
    );

    // @ts-ignore
    let delta = Math.abs(currentDateTime - clockedInDateTimeObj) / 1000;

    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    const seconds = delta % 60;

    if (days > 0) {
      days *= 24;
      hours += days;
    }
    let fHours;
    let fMinutes;
    let fSeconds;

    if (hours < 10) {
      fHours = '0' + String(hours);
    } else {
      fHours = hours;
    }

    if (minutes < 10) {
      fMinutes = '0' + String(minutes);
    } else {
      fMinutes = minutes;
    }

    if (seconds < 10) {
      fSeconds = '0' + String(seconds);
    } else {
      fSeconds = seconds;
    }
    return `${fHours}:${fMinutes}:${fSeconds}`;
  }

  getTimeSpentAtWorkDifference(clockedInDateTime, clockedOutDateTime) {
    const time1 = clockedOutDateTime.split(/[-T.:+/ ]/);
    const clockedOutDateTimeObj = new Date(
      `${time1[1]}/${time1[2]}/${time1[0]} ${time1[3]}:${time1[4]}:${time1[5]}`
    );
    const time2 = clockedInDateTime.split(/[-T.:+/ ]/);
    const clockedInDateTimeObj = new Date(
      `${time2[1]}/${time2[2]}/${time2[0]} ${time2[3]}:${time2[4]}:${time2[5]}`
    );
    // @ts-ignore
    let delta = Math.abs(clockedOutDateTimeObj - clockedInDateTimeObj) / 1000;

    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    const seconds = delta % 60;

    if (days > 0) {
      days *= 24;
      hours += days;
    }
    let fHours;
    let fMinutes;
    let fSeconds;

    if (hours < 10) {
      fHours = '0' + String(hours);
    } else {
      fHours = hours;
    }

    if (minutes < 10) {
      fMinutes = '0' + String(minutes);
    } else {
      fMinutes = minutes;
    }

    if (seconds < 10) {
      fSeconds = '0' + String(seconds);
    } else {
      fSeconds = seconds;
    }
    return `${fHours}:${fMinutes}:${fSeconds}`;
  }

  formatDateWithStroke(dateTime) {
    try {
      const dateArr = dateTime.split(/[-T.:+ ]/);
      return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`;
    } catch (err) {
      return null;
    }
  }

  describeDays(days) {
    let word;
    if (days < 31) {
      if (days === 1) {
        word = `${days} day`;
      } else {
        word = `${days} days`;
      }
    } else if (days >= 31 && days <= 365) {
      const month = Math.floor(days / 30);
      if (month === 1) {
        word = `${month} month`;
      } else {
        word = `${month} months`;
      }
    } else {
      const year = Math.floor(days / 365);
      if (year === 1) {
        word = `${year} year`;
      } else {
        word = `${year} years`;
      }
    }
    return word;
  }

  createAcronym(value) {
    const matches = value?.match(/\b(\w)/g);
    return matches?.join('');
  }

  checkExceedStartDate(startDate) {
    const time1 = moment()
      .format()
      .split(/[-T.:+]/);
    const date1 = new Date(`${time1[1]}/${time1[2]}/${time1[0]}`);
    let exceedStartDate;

    const time2 = startDate.split(/[-T.:+]/);
    const date2 = new Date(`${time2[1]}/${time2[2]}/${time2[0]}`);

    exceedStartDate = date1 > date2;
    return exceedStartDate;
  }

  todayDateTime() {
    const time = moment()
      .format()
      .split(/[-T.:+]/);
    return `${time[0]}-${time[1]}-${time[2]} ${time[3]}:${time[4]}:00`;
  }

  formatDateTimeToTime(dateTime) {
    const time = this.extractDateTime(dateTime).split(' ');
    return `${time[1]}${time[2].toLowerCase()}`;
  }

  // get time in words
  getTimeInWords(dateTime) {
    const date1 = dateTime.split(/[-T.:+ ]/);
    // const date2 = new Date(`${time2[1]}/${time2[2]}/${time2[0]} ${time2[3]}:${time2[4]}:${time2[5]}`);
    const date2 = new Date(
      date1[0],
      date1[1] - 1,
      date1[2],
      date1[3],
      date1[4],
      date1[5]
    );
    const ms = Date.now() - date2.getTime();

    const seconds = Math.round(ms / 1000);
    const minutes = Math.round(ms / 60000);
    const hours = Math.round(ms / 3600000);
    const days = Math.round(ms / 86400000);
    const months = Math.round(ms / 2592000000);
    const years = Math.round(ms / 31104000000);

    if (seconds < 0) {
      switch (true) {
        case Math.abs(seconds) < 60:
          return `${seconds} second(s) ago`;
        case Math.abs(minutes) < 60:
          return `${minutes} minute(s) ago`;
        case Math.abs(hours) < 24:
          return `${hours} hour(s) ago`;
        case Math.abs(days) < 30:
          return `${days} day(s) ago`;
        case Math.abs(months) < 12:
          return `${months} month(s) ago`;
        default:
          return `${years} year(s) ago`;
      }
    } else {
      switch (true) {
        case seconds < 60:
          return `${seconds} second(s) ago`;
        case minutes < 60:
          return `${minutes} minute(s) ago`;
        case hours < 24:
          return `${hours} hour(s) ago`;
        case days < 30:
          return `${days} day(s) ago`;
        case months < 12:
          return `${months} month(s) ago`;
        default:
          return `${years} year(s) ago`;
      }
    }
  }

  getRoomHeaderColorClass(room) {
    if (room.notForSale !== 'true') {
      if (room.cleanRoom === 'true') {
        if (room.roomStatus === 'occupied') {
          if (room.cleaningType === 'refresh') {
            return 'bgRefreshCleanOccupied';
          } else if (room.cleaningType === 'deep') {
            return 'bgDeepCleanOccupied';
          } else if (room.cleaningType === 'requested') {
            return 'bgRequestedCleanOccupied';
          }
        } else if (room.roomStatus === 'available') {
          if (room.cleaningType === 'refresh') {
            return 'bgRefreshCleanAvailable';
          } else if (room.cleaningType === 'deep') {
            return 'bgDeepCleanAvailable';
          } else if (room.cleaningType === 'requested') {
            return 'bgRequestedCleanAvailable';
          }
        } else if (room.roomStatus === 'reserved') {
          if (room.cleaningType === 'refresh') {
            return 'bgRefreshCleanReserved';
          } else if (room.cleaningType === 'deep') {
            return 'bgDeepCleanReserved';
          } else if (room.cleaningType === 'requested') {
            return 'bgRequestedCleanReserved';
          }
        } else if (room.roomStatus === 'booked') {
          if (room.cleaningType === 'refresh') {
            return 'bgRefreshCleanBooked';
          } else if (room.cleaningType === 'deep') {
            return 'bgDeepCleanBooked';
          } else if (room.cleaningType === 'requested') {
            return 'bgRequestedCleanBooked';
          }
        }
      } else {
        if (room.roomStatus === 'available') {
          return 'bg-success';
        }else if (room.daysLeft === - 1 && room.roomStatus === 'occupied') {
          return 'pg-danger';
        } else if (room.roomStatus === 'occupied' && room.daysLeft !== - 1 ) {
          return 'bg-dark';
        } else if (room.roomStatus === 'reserved') {
          return 'header-reserved';
        } else if (room.roomStatus === 'booked') {
          return 'header-booked';
        }
      }
    } else {
      return 'bg-secondary';
    }
  }

  convertDateToObject(dateTime = null) {
    const currentDateTimeArray = [];
    let tempDateTime;
    if (dateTime) {
      tempDateTime = dateTime.split(/[-T.:+ ]/);
    } else {
      const currentDateTime = moment().format('YYYY-MM-DD HH:mm:SS');
      tempDateTime = currentDateTime.split(/[-: ]/);
    }
    for (const i of tempDateTime) {
      currentDateTimeArray.push(+i);
    }

    return {
      year: currentDateTimeArray[0],
      month: currentDateTimeArray[1],
      day: currentDateTimeArray[2]
    };
  }

  formatDateToObject(date) {
    const dateArr = date.split(/[-T.: ]/);
    return {
      year: +dateArr[0],
      month: +dateArr[1],
      day: +dateArr[2]
    };
  }

  addDaysToDate(date, numOfDays) {
    const dateArr = date.split(/[-T.:+ ]/);
    const dateObj = new Date(
      +dateArr[0],
      +dateArr[1] - 1,
      +dateArr[2] + numOfDays
    );
    return `${dateObj.getFullYear()}-${dateObj.getMonth() +
    1}-${dateObj.getDate()}`;
  }

  convertDateTimeToString(dateTime, type) {
    const time = type === 'start' ? '00:00:01' : '23:59:59';
    return `${dateTime.year}-${dateTime.month}-${dateTime.day} ${time}`;
  }

  convertDateToString(date) {
    return `${date.year}-${date.month}-${date.day}`;
  }
  convertDateTimeToStringObj(dateTimeArray) {
    // console.log(dateTimeArray);
    return `${dateTimeArray[6]}${dateTimeArray[7]}${dateTimeArray[8]}${dateTimeArray[9]}-${dateTimeArray[4]}-${dateTimeArray[0]}${dateTimeArray[1]} ${dateTimeArray[10]}${dateTimeArray[11]}${dateTimeArray[12]}${dateTimeArray[13]}${dateTimeArray[14]}${dateTimeArray[15]}:00`;
  }

  getCurrentDateTime() {
    return moment().format('DD/MM/YYYY hh:mm A');
  }

  getTodayDate() {
    return moment().format('DD/MM/YYYY');
  }

  getCurrentEmployeeId() {
    return localStorage.getItem('employeeId');
  }

  getCurrentBusinessId() {
    return localStorage.getItem('businessId');
  }

  getCurrentBusiness() {
    return localStorage.getItem('current:business');
  }

  formatTimeToString(time) {
    return `${time.hour}:${time.minute}:00`;
  }

  formatDateToString(date) {
    return `${date.year}-${date.month}-${date.day}`;
  }
}
