import { Component, OnInit } from '@angular/core';

import { RandomListsService } from '../../shared/random-lists.service';
import { HttpService } from '../../shared/http.service';
// import {AuthService} from '../../shared/auth.service';
import {HttpHeaders} from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { MenuModel } from '../../models/hotel-menu.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-hotel-menu',
  templateUrl: './hotel-menu.component.html',
  styleUrls: ['./hotel-menu.component.css']
})
export class HotelMenuComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private httpService: HttpService,
    private route: ActivatedRoute,
    // private authService: AuthService
    private randomList: RandomListsService,
  ) {
    this.getHotelMenu();
  }

  // menu;
  showCat;
  details;
  modalRef;
  menuData;
  allMenuData;
  menuId;
  locationId;
  loading = true;
  totalRecords;
  customizeDisplay = false;
  comboType;
  newMenu;
  comboTypeCategory;
  selectedComboCategory;
  selectedComboFoodCategory;
  allComboCategory;
  hotelId;
  majorCategoryId;
  subCategories;
  subCategoryId;
  selectedMiniCategories;
  toggleMajorCategoryId;
  toggleSubCategoryId;
  // @ts-ignore
  // @ts-ignore
  menuComboProducts;
  allMenuComboProducts;
  currentMenuId;
  menuLocationCode;



  // hotelId = localStorage.getItem('hotelId');

   subCategorySection = document.querySelector('.subCategory');
  ngOnInit(): void {
    this.selectedComboFoodCategory = this.randomList.ComboCategories;
    this.allComboCategory = this.randomList.ComboCategories;
    // console.log(' this.comboCategory',  this.comboCategory);
     // this.menuData =
    //
    // ;
    this.showCat = 'majorCategoryLabel';
    this.route.params.subscribe((params: Params) => {
      this.hotelId = params.hotelId;
      this.menuId = params.menuId;
      this.locationId = params.locationId;
      this.menuLocationCode = params?.menuLocationCode;
      this.getHotelMenu();
      this.getHotelDetails();
      // console.log('this.locationId', this.locationId);
    });
  }

  getHotelDetails(){
    if (this.hotelId) {
      this.httpService.getHotelInfo(
        `hotels/${this.hotelId}`).subscribe(res => {
        this.details = res;
      });
    }
  }

  getHotelMenu() {
    this.loading = true;
    // console.log('this.hotelId', this.hotelId);
    let endPoint;
    if (this.hotelId && this.locationId) {
      endPoint = `hotels/getMenu/${this.hotelId}/${this.menuId}?menuLocationCode=${this.menuLocationCode}&locationId=${this.locationId}`;
    } else if (this.menuLocationCode){
      endPoint = `hotels/getMenu/${this.hotelId}/${this.menuId}?menuLocationCode=${this.menuLocationCode}`;
    }
    // console.log('this.endPoint', endPoint);
    if (this.hotelId || this.menuLocationCode) {
      this.httpService.getMenu(
        endPoint).
      subscribe(res => {
          // @ts-ignore
          const {data, hotelInfo} = res;
          // console.log('res', res);
          this.menuData = data;
          this.currentMenuId = data[0]?.menuId;
          // console.log(' this.currentMenuId',  this.currentMenuId);
          this.details = hotelInfo;
          // console.log('hotelInfo', hotelInfo);
          // console.log('  this.menuData',   this.menuData);
          this.newMenu = JSON.parse(JSON.stringify(data));
          this.allMenuData = JSON.parse(JSON.stringify(data)) ;
          // this.originalProducts = res;
          this.totalRecords = this.menuData?.length;
          this.loading = false;
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  filterByName(event) {
    const name = event.target.value;
 this.allMenuComboProducts.subCategories  = this.menuComboProducts.subCategories.filter((e) => e.menuProducts);
    // tslint:disable-next-line:max-line-length
    // this. this.allMenuComboProducts.subCategories = this.menuComboProducts.subCategories.filter((e) => e.menuProducts ).
    // console.log('this.allMenuComboProducts.subCategories ', this.allMenuComboProducts.subCategories);
  }



  openLg(content) {
    this.modalRef = this.modalService.open(content, {size: 'lg'});
    this.modalRef.result.then((result) => {
    }, (reason) => {
    });
  }
  openComboType(content, comboType){
    this.loading = true;
    this.openLg(content);
    this.menuData = this.allMenuData.products.filter((e) => e.comboType === comboType);
    this.loading = false;
    this.selectedComboFoodCategory = this.allComboCategory.filter((c) => c.comboType === comboType);
  }
  toggleSubCat(content, majorCategoryId){
    this.openLg(content);
  // this.menuData = this.newMenu.filter((e) => e.majorCategoryId === majorCategoryId);
    // this.showCat = 'subEatCategoryLabel';
  }
  toggleMiniCat(miniCategories, majorCategoryId){
    this.toggleMajorCategoryId = majorCategoryId;
    this.selectedMiniCategories = miniCategories;
    // this.menuData = this.newMenu.filter((e) => e.subCategories );
    // console.log('miniCategories', miniCategories);
    this.showCat = 'miniEatCategory';
  }

  showMajorCat(){
    this.showCat = 'majorCategoryLabel';
    this.getHotelMenu();
  }

  openMenuProductModal(content,  majorCategoryId){
    this.loading = true;
    this.openLg(content);
    this.httpService.getMenu(
      `hotels/getMenuProdByMajorId/${this.currentMenuId}/${majorCategoryId}`).subscribe(res => {
        this.menuComboProducts = res;
        this.allMenuComboProducts = res;
        // console.log('this.menuComboProducts', this.menuComboProducts);
        // this.totalRecords = this.menuComboProducts?.length;
        this.loading = false;
      },
      err => {
        console.log(err);
      }
    );
  }


}



