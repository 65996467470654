import { INavData } from '@coreui/angular';

export const hotelsSuperAdminNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-grid'
  },
  {
    name: 'Rooms',
    url: '/room',
    icon: 'icon-home',
    children: [
      {
        name: 'Checkin',
        url: '/room/checkin',
        icon: 'icon-login'
      },
      {
        name: 'Checkout',
        url: '/room/checkout',
        icon: 'icon-logout'
      },
      {
        name: 'Make Reservation',
        url: '/room/make-reservation',
        icon: 'icon-plus'
      },
      {
        name: 'Reservations',
        url: '/room/reservations',
        icon: 'icon-close'
      }
    ]
  },
  {
    name: 'Guests',
    url: '/guests',
    icon: 'icon-people',
    children: [
      {
        name: 'Guests List',
        url: '/guests/guests-list',
        icon: 'icon-list'
      },
      {
        name: 'Customer Payment',
        url: '/guests/pay',
        icon: 'icon-credit-card'
      },
      {
        name: 'BFK/Mini',
        url: '/guests/bfkmini',
        icon: 'icon-cup'
      }
    ]
  },
  {
    name: 'Cash Register',
    url: '/cash-register',
    icon: 'icon-notebook',
    children: [
      {
        name: 'Manage Cash Register',
        url: '/cash-register/manage-cash-register',
        icon: 'icon-book-open'
      },
      {
        name: 'Cash Register Audit',
        url: '/cash-register/cash-register-audit',
        icon: 'icon-calculator'
      }
    ]
  },
  {
    name: 'Customer Service',
    url: '/customer-service',
    icon: 'icon-cup',
    children: [
      {
        name: 'Laundry Service',
        url: '/customer-service/laundry-service',
        icon: 'icon-layers'
      },
      {
        name: 'House Keeping',
        url: '/customer-service/housekeeping',
        icon: 'icon-loop'
      },
      {
        name: 'Room Service',
        url: '/customer-service/room-service',
        icon: 'icon-drawer'
      },
      {
        name: 'Issues/Comments',
        url: '/customer-service/issues-comments',
        icon: 'icon-volume-1'
      }
    ]
  },
  {
    name: 'MIS',
    url: '/mis',
    icon: 'icon-folder-alt',
    children: [
      {
        name: 'Staff Dashboard',
        url: '/mis/staff-duties/attendance-dashboard',
        icon: 'icon-layers'
      },
      {
        name: 'Duty Logs',
        url: '/mis/staff-duties/duty-logs',
        icon: 'icon-loop'
      },
      {
        name: 'Staffs Roster',
        url: '/mis/staff-duties/staffs-roster',
        icon: 'icon-event'
      },
      {
        name: 'Rooms Inspection',
        url: '/mis/inspections/rooms-list',
        icon: 'icon-doc'
      },
      {
        name: 'Room Cleaning',
        url: '/mis/house-cleaning/clean-room',
        icon: 'icon-refresh'
      },
      {
        name: 'Event Lists',
        url: '/mis/event-lists',
        icon: 'icon-event'
      }
    ]
  },
  {
    name: 'Reports',
    url: '/reports',
    icon: 'icon-chart',
    children: [
      {
        name: 'Overview',
        url: '/reports/overview',
        icon: 'icon-bag'
      },
      {
        name: 'Graphical Overview',
        url: '/reports/graphical-overview',
        icon: 'icon-graph'
      },
      {
        name: 'Rooms Report',
        url: '/reports/rooms-report',
        icon: 'icon-directions'
      },
      {
        name: 'Revenues Report',
        url: '/reports/revenues-report',
        icon: 'icon-wallet'
      },
      {
        name: 'Guests Report',
        url: '/reports/guests-report',
        icon: 'icon-emotsmile'
      },
      {
        name: 'Management Report',
        url: '/reports/mgt-reports',
        icon: 'icon-organization'
      },
      // {
      //   name: 'System Report',
      //   url: '/reports/system-admin-report',
      //   icon: 'icon-organization'
      // }
    ]
  },
  // {
  //   name: 'Dashboard-Reports',
  //   url: '/dashboard-reports',
  //   icon: 'icon-chart'
  // },
  {
    divider: true
  },
  {
    title: true,
    name: 'Accounting'
  },
  {
    name: 'Account',
    icon: 'icon-settings',
    children: [
      {
        name: 'Create/Edit Guest',
        url: '/admin/accounting/editcreate',
        icon: 'icon-user-following'
      },
      {
        name: 'Guests Ledger',
        url: '/admin/accounting/guest-ledger-records',
        icon: 'icon-layers'
      },
      {
        name: 'Royalty Club',
        url: '/admin/accounting/create-edit-club',
        icon: 'icon-people'
      },
      {
        name: 'Guests Refund',
        url: '/admin/accounting/refunds',
        icon: 'icon-action-redo'
      },
      {
        name: 'View Manager Credit',
        url: '/admin/accounting/view-credit',
        icon: 'icon-size-fullscreen'
      },
      {
        name: 'Petty Cash',
        url: '/admin/accounting/petty-cash',
        icon: 'icon-present'
      },
      {
        name: 'Petty Cash History',
        url: '/admin/accounting/pc-history',
        icon: 'icon-list'
      },
      {
        name: 'Cash Management',
        url: '/admin/accounting/cash-management',
        icon: 'icon-doc'
      },
      {
        name: 'CM History',
        url: '/admin/accounting/cm-history',
        icon: 'icon-docs'
      },
      {
        name: 'Bill Payment',
        url: '/admin/accounting/bill-payment',
        icon: 'icon-calculator'
      },
      // {
      //   name: 'Process HO Payment',
      //   url: '/admin/accounting/process-hotel-payment',
      //   icon: 'icon-calculator'
      // },
      // {
      //   name: 'View HO Records',
      //   url: '/admin/accounting/view-process-payment',
      //   icon: 'icon-book-open'
      // }
    ]
  },
  {
    name: 'HR',
    icon: 'icon-settings',
    children: [
      {
        name: 'View Attendance',
        url: '/admin/hr/attendance',
        icon: 'icon-event'
      },
      {
        name: 'Monthly Attendance',
        url: '/admin/hr/monthly-attendance',
        icon: 'icon-layers'
      },
      {
        name: 'View Payroll',
        url: '/admin/hr/payrolls',
        icon: 'icon-film'
      }
    ]
  },
  {
    divider: true
  },
  {
    title: true,
    name: 'Admin'
  },
  {
    name: 'Hotels',
    icon: 'icon-settings',
    children: [
      // {
      //   name: 'Create Hotel',
      //   url: '/admin/hotels/create',
      //   icon: 'icon-plus'
      // },
      {
        name: 'View Hotels',
        url: '/admin/hotels/list',
        icon: 'icon-bag'
      },
      {
        name: 'Hotel Maintenance',
        url: '/admin/hotels/assets',
        icon: 'icon-folder-alt'
      }
    ]
  },
  {
    name: 'Employees',
    icon: 'icon-settings',
    children: [
      {
        name: 'Employees Dashboard',
        url: '/admin/employees/hotels',
        icon: 'icon-screen-desktop'
      },
      {
        name: 'Create Employee',
        url: '/admin/employees/create',
        icon: 'icon-user-follow'
      },
      {
        name: 'Surcharges',
        url: '/admin/employees/surcharges',
        icon: 'icon-pin'
      },
      {
        name: 'Employees Control',
        url: '/admin/employees/control',
        icon: 'icon-equalizer'
      }
    ]
  },
  /*{
    name: 'Requests',
    icon: 'icon-settings',
    children: [
      {
        name: 'Business Requests',
        url: '/admin/requests/view-business-request',
        icon: 'icon-screen-desktop'
      }
    ]
  }*/
];
