import { Component, OnInit } from '@angular/core';
import { navItems } from '../../navs/defaultNav';
import { AuthService } from '../../shared/auth.service';
import { EmployeeModel } from '../../models/employee.model';
import { HttpService } from '../../shared/http.service';
import { HotelModel } from '../../models/hotel.model';
import { frontDeskNavItems } from '../../navs/frontDeskNav';
import { adminNavItems } from '../../navs/adminNav';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { registerOperatorNavItems } from '../../navs/registerOperatorNav';
import * as moment from 'moment';
import { RandomListsService } from '../../shared/random-lists.service';
import { accountantNavItems } from '../../navs/accountantNav';
import { superAdminNavItems } from '../../navs/superAdminNav';
import { marketerNavItems } from '../../navs/marketerNav';
import { housekeeperNavItems } from '../../navs/houseKeeperNav';
import {superAccountantNav} from '../../navs/superAccountantNav';
import { ipmsLightModeNavItems } from '../../navs/ipmsLightMode';
import { customerServiceNavItems } from '../../navs/customerServiceNav';
import { marketerAccountantNav } from '../../navs/marketerAccountantNav';
import { maintenanceNavItems } from '../../navs/maintenanceNav';
import { waiterFDNavItems } from '../../navs/waiterFDNav';
import { supervisorNavItems } from '../../navs/supervisorNav';
import { hodAccountsNavItems } from '../../navs/HOD-Accounts';
import { hodFbNavItems } from '../../navs/HOD-Fb';
import { hodFdNavItems } from '../../navs/HOD-Fd';
import { hodHkNavItems } from '../../navs/HOD-Hk';
import { hodMaintenanceNavItems } from '../../navs/HOD-Maintenance';
import { hodSecurityNavItems } from '../../navs/HOD-Security';
import { hodOthersNavItems } from '../../navs/HOD-Others';
import { hotelsSuperAdminNavItems } from '../../navs/hotelsSuperAdmin';
import { cardKeyAdminNavItems } from '../../navs/cardKeyAdminNav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  navItems;
  employee: EmployeeModel = new EmployeeModel();
  hotel: HotelModel = new HotelModel();
  registerId = localStorage.getItem('registerId');
  employeeId = localStorage.getItem('employeeId');
  hotelMode = sessionStorage.getItem('hotelMode');
  breadCrumImg;

  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private toastr: ToastrService,
    private router: Router,
    private randomList: RandomListsService
  ) {}

  ngOnInit() {
    this.getEmployeeDetails();
    this.getHotelInfo();

  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logOut() {
    this.registerId = localStorage.getItem('registerId');
    this.insertLogOutDetails();
  }

  insertLogOutDetails() {
    const momentDateTime = moment().format('YYYY-MM-DD HH:mm:00');
    const logoutDateTime = this.randomList.parseDateTime(momentDateTime);
    const details = {
      employeeId: this.employeeId,
      logoutDateTime
    };
    this.authService
      .insertLogoutDetails('employees/insertLogout', details)
      .subscribe(
        res => {
          this.authService.logOut();
        },
        err => {
          console.log(err);
        }
      );
  }

  getEmployeeDetails() {
    const employeeId = localStorage.getItem('employeeId');
    const hotelId = localStorage.getItem('hotelId');
    let path;
    if (hotelId) {
      path = `employees/${employeeId}?hotelId=${hotelId}`;
    } else {
      path = `employees/${employeeId}`;
    }

    this.httpService.getEmployeeDetails(path).subscribe(
      res => {
        this.employee = res;
        // console.log('default layouts', this.employee);
        if (this.employee.accessRole === 'front desk' || this.employee.accessRole === 'fb') {
          this.navItems = frontDeskNavItems;
        } else if (this.employee.accessRole === 'admin') {
          this.navItems = adminNavItems;
        }
        else if (this.employee.accessRole === 'supervisor') {
          this.navItems = supervisorNavItems;
        }else if (this.employee.hotelMode === 'light') {
          this.navItems = ipmsLightModeNavItems;
        } else if (
          this.employee.accessRole === 'manager' &&
          this.employee.operateRegister === 'true'
        ) {
          this.navItems = registerOperatorNavItems;
          // customerServiceNavItems
        } else if (this.employee.accessRole === 'customer_services') {
          this.navItems = customerServiceNavItems;
        } else if (this.employee.accessRole === 'maintenance') {
          this.navItems = maintenanceNavItems;
        }  else if (this.employee.accessRole === 'waiter_fd') {
          this.navItems = waiterFDNavItems;
        } else if (this.employee.accessRole === 'accountant') {
          this.navItems = accountantNavItems;
        } else if (this.employee.accessRole === 'marketer') {
          this.navItems = marketerNavItems;
        } else if (this.employee.accessRole === 'housekeeper' || this.employee.accessRole === 'customerservice') {
          this.navItems = housekeeperNavItems;
        } else if (this.employee.accessRole === 'super_accountant') {
          this.navItems = superAccountantNav;
        } else if (this.employee.accessRole === 'marketer_accountant') {
          this.navItems = marketerAccountantNav;
        } else if (this.employee.accessRole === 'cardkey-admin') {
          this.navItems = cardKeyAdminNavItems;
        }  else if (this.employee.accessRole === 'superadmin') {
          this.navItems = superAdminNavItems;
        }  else if (this.employee.accessRole === 'hotel-superadmin') {
          this.navItems = hotelsSuperAdminNavItems;
        } else if (this.employee.accessRole === 'hod_accounts') {
          this.navItems = hodAccountsNavItems;
        } else if (this.employee.accessRole === 'hod_fb') {
          this.navItems = hodFbNavItems;
        }
        else if (this.employee.accessRole === 'hod_fd') {
          this.navItems = hodFdNavItems;
        }
        else if (this.employee.accessRole === 'hod_houseKeeper') {
          this.navItems = hodHkNavItems;
        }
        else if (this.employee.accessRole === 'hod_maintenance') {
          this.navItems = hodMaintenanceNavItems;
        }
        else if (this.employee.accessRole === 'hod_security') {
          this.navItems = hodSecurityNavItems;
        }
        else if (this.employee.accessRole === 'hod_others') {
          this.navItems = hodOthersNavItems;
        }
        else {
          this.navItems = [];
        }

        if (this.employee.picture === null) {
          this.employee.picture = '/assets/no_pix1.png';
        }

        if (this.employee.hotelMode === 'light') {
          this.breadCrumImg =  'assets/logos/ipms-light.jpeg';
        } else if (this.employee.hotelMode === 'golden') {
          this.breadCrumImg =  'assets/logos/ipms-light.jpeg';
        } else {
          this.breadCrumImg =  'assets/logos/ipms-complete.jpeg';
        }

      },
      err => {
        console.log(err);
      }
    );
  }

  switchHotels() {
    localStorage.removeItem('registerId');
    this.router.navigate(['/hotels']);
  }

  viewProfile() {
    const employeeId = localStorage.getItem('employeeId');
    this.router.navigate([`admin/employees/${employeeId}`]);
  }

  getHotelInfo() {
    const hotelId = localStorage.getItem('hotelId');
    if (+hotelId === 0) {
      this.hotel.hotelName = 'No hotel';
    } else {
      this.httpService.getHotelInfo(`hotels/${hotelId}`).subscribe(
        res => {
          this.hotel = res;
        },
        err => {
          console.log(err);
        }
      );
    }
  }
}
